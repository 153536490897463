import React, { Component } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../../assets/css/login.css';
import { BASE_URL } from '../../config';
import SweetAlert from 'sweetalert2-react';
import { withTranslation  } from 'react-i18next';
class ForgotPassword extends Component {
    state = {
        email: "",
        msg_ko: ""
    };
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);

    }
    componentDidMount() {
        if (this.props.isAuth === true) {
            this.props.history.push("/dashboard");
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isAuth === true) {
            this.props.history.push("/dashboard");
        }
    }

    handleInputChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            msg_ko: ""
        });
    };
    handleSubmit = e => {
        e.preventDefault();
        if (this.state.email.trim()) {
            const { email } = this.state;
            const currentLanguage = this.props.i18n.language || this.props.i18n.options.fallbackLng[0]
            axios.post(`${BASE_URL}/api/auth/pass`, { email, locale: currentLanguage })
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({ show: true, msg_ko: "Veulliez vérifer votre Email ! " });
                    } else {
                        if (response.data.message === "email_not_exist") {
                            this.setState({ show: true, msg_ko: "Cet adresse Email n'existe pas " });
                        }
                    }
                })
                .catch(error => {
                    throw (error);
                });
        }
    };
    render() {
        return (
            <section className="main">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-5 col-10">
                            <h1 className="text-center my-3">{this.props.t('forgot_password')}</h1>
                            <Form className="py-3" onSubmit={this.handleSubmit}>
                                <Form.Group controlId="formLoginEmail">
                                    <Form.Label>{this.props.t('email')}</Form.Label>
                                    <Form.Control type="email" placeholder={this.props.t('email')} name="email" onChange={this.handleInputChange} value={this.state.email} required />
                                </Form.Group>
                                <div className="text-center">

                                    <Button variant="outline-dark" type="submit">{this.props.t('submit')}</Button>

                                </div>
                            </Form>
                            <hr className="w-100" />
                            <div className="d-flex justify-content-around py-3">
                                <Link to="/login">{this.props.t('btn_login')}</Link>
                                <span> | </span>
                                <Link to="/register">{this.props.t('signup')}</Link>
                            </div>
                        </div>
                    </div>
                    <SweetAlert
                        show={this.state.show}
                        text={this.state.msg_ko}
                        onConfirm={() => this.setState({ show: false })}
                    />
                </div>
            </section>
        );
    }
}

export default withTranslation()(ForgotPassword);
