import React, { Component } from 'react';

import { withTranslation  } from 'react-i18next';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/es/index";
import Select, {components} from 'react-select';

class DashboardFilter extends Component {
    render() {
        return(
          
            <div className="col-md-12 col-sm-12">
                {/* Form START*/}
                <div class="card shadow p-3 mb-5 bg-white rounded text-center">
                    <div class="card-body">
                        <form onSubmit={this.props.handleSubmit}>
                            <div class="form-row align-items-center">
                                <div class="col-md-5 col-sm-6 my-1">
                                    <div class="input-group mb-2 mr-sm-2">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">Date From</div>
                                        </div>
                                        <DatePicker
                                            className="form-control"
                                            selected={this.props.filterDateFrom}
                                            value={this.props.filterDateFrom}
                                            onChange={this.props.handleDateFromChange}
                                            showTimeSelect="true"
                                            // dateFormat="Pp"
                                            dateFormat="dd/MM/yyyy HH:mm"
                                            timeFormat="HH:mm"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-5 col-sm-6 my-1">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">Date To</div>
                                        </div>
                                        <DatePicker
                                            className="form-control"
                                            selected={this.props.filterDateTo}
                                            value={this.props.filterDateTo}
                                            onChange={this.props.handleDateToChange}
                                            showTimeSelect
                                            // dateFormat="Pp"
                                            dateFormat="dd/MM/yyyy HH:mm"
                                            timeFormat="HH:mm"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="form-row align-items-center">
                                <div class="col-md-5 col-sm-6 my-1">
                                    <div class="form-group my-1">
                                        <Select
                                            onChange={this.props.handlePosChange}
                                            async
                                            withAll={true}
                                            closeMenuOnSelect={true}
                                            options={this.props.posOptions}
                                            placeholder={this.props.t('pos')}
                                            defaultValue=''
                                            isClearable={true}
                                            value={this.props.selectedFilterPos}               
                                        />
                                    </div>
                                </div>
                                <div class="col-auto my-1">
                                    <button type="submit" class="btn btn-bma-primary">Submit</button>
                                </div>
                                <div class="col-auto my-1">
                                <button type="button"  onClick={this.props.handleClear} class="btn btn-bma-secondary">Clear</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {/* Form END*/}
            </div>
        )
      }
}
export default withTranslation()(DashboardFilter)