import React, { Component } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../config.js';
import { Button, Form, Card } from 'react-bootstrap';
import SweetAlert from 'sweetalert2-react';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/es/index";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';

class AddEvent extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            nom: "",
            event_id: "",
            debut: new Date(),
            fin: null,
            prix: "",
            description: "",
            url: "",
            events: [],
            errors: {}
          };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleStartChange = this.handleStartChange.bind(this);
        this.handleEndChange = this.handleEndChange.bind(this);
    }

    handleStartChange(date) {
        this.setState({
          debut: date,
          fin: date,
        });
    }
    handleEndChange(date) {
        this.setState({
          fin: date
        });
    }

    componentDidMount () {
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization
        };
        // get evants
        axios.get(`${BASE_URL}/api/events?type=of`, { headers })
            .then(res => {
                const events = res.data.events;
                this.setState({ events: events });
            })
            .catch(error => {
                console.log(error);
            });
    }

    handleInputChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        if (this.state.nom.trim()) {
            const { nom, event_id, debut, fin, prix, description, url } = this.state;
            const Authorization = localStorage.getItem('token');
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': Authorization
            };
            // reset form errors
            this.setState({
                errors: {}
            });
            // get dates
            const dateDebut = moment(debut);
            const dateFin = moment(fin);
            if (fin && dateFin && debut && dateDebut && (dateFin.unix() < dateDebut.unix() )) {
                // set field errors
                this.setState({errors: { 
                    debut: {field: "debut", validatorKey: "incompatible"},
                    fin: {field: "fin", validatorKey: "incompatible"}
                }})
                throw ('Incompatible Dates');
            }

            axios.post(`${BASE_URL}/api/programmes`, { nom, event_id, debut, fin, prix, description, url }, { headers })
                .then(response => {
                    if (response.data.status === 'success') {
                        this.setState({ show: true });
                        setTimeout(() => {
                            this.props.history.push("/dashboard/events");
                        }, 2000);
                    } else {
                        console.log('KO');
                        if (response.data.errors) {
                            this.setState({ errors: response.data.errors });
                        }
                    }
                })
                .catch(error => {
                    throw (error);
                });
        } else {
            this.setState({errors: { nom: {field: "nom", validatorKey: "isNotNull"}}})
        }
    };



    handleReset = () => {
        this.setState({
            nom: "",
            color: "",
            errors: {}
        });
    };

    render() {
        return (
            <div className="col-md-10">
                <h1>{this.props.t('add_event')}</h1>
                <div className="row justify-content-left">
                    <div className="col-md-6">
                        <Form className="py-3" onSubmit={this.handleSubmit}>
                            <Form.Group controlId="formLabel">
                                <Form.Label>{this.props.t('name')}</Form.Label>
                                <Form.Control isInvalid={!!(this.state.errors && "nom" in this.state.errors)} type="text" placeholder={this.props.t('name')} name="nom" onChange={this.handleInputChange} required oninvalid="this.setCustomValidity('This field cannot be empty')" oninput="setCustomValidity('')"/>
                                { (this.state.errors && "nom" in this.state.errors) ? <p className="text-right text-danger">{this.props.t(`error.${this.state.errors.nom.field}.${this.state.errors.nom.validatorKey}`)}</p> : <p></p> }
                            </Form.Group>
                            <Form.Group controlId="formCategorie">
                                <Form.Label>{this.props.t('categorie')}</Form.Label>
                                <Form.Control as="select" name="event_id" onChange={this.handleInputChange}>
                                <option value="">{this.props.t('choose_categorie')}</option>
                                {
                                    this.state.events.map((event, index) => 
                                        <option key={index} value={event.id}>{event.nom}</option>
                                )}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="formDebut">
                                <Form.Label>{this.props.t('debut')}</Form.Label>
                                <br/>
                                <DatePicker
                                    className="form-control"
                                    selected={this.state.debut}
                                    onChange={this.handleStartChange}
                                    showTimeSelect
                                    // dateFormat="Pp"
                                    dateFormat="dd/MM/yyyy HH:mm"
                                    timeFormat="HH:mm"
                                />
                                { (this.state.errors && "debut" in this.state.errors) ? <p className="text-right text-danger">{this.props.t(`error.${this.state.errors.debut.field}.${this.state.errors.debut.validatorKey}`)}</p> : <p></p> }
                            </Form.Group>
                            <Form.Group controlId="formFin">
                                <Form.Label>{this.props.t('fin')}</Form.Label>
                                <br/>
                                <DatePicker
                                    className="form-control"
                                    selected={this.state.fin}
                                    onChange={this.handleEndChange}
                                    showTimeSelect
                                    // dateFormat="Pp"
                                    dateFormat="dd/MM/yyyy HH:mm"
                                    timeFormat="HH:mm"
                                />
                                { (this.state.errors && "fin" in this.state.errors) ? <p className="text-right text-danger">{this.props.t(`error.${this.state.errors.fin.field}.${this.state.errors.fin.validatorKey}`)}</p> : <p></p> }
                            </Form.Group>
                            <Form.Group controlId="formPrix">
                                <Form.Label>{this.props.t('prix')}</Form.Label>
                                <Form.Control type="text" placeholder={this.props.t('prix')} name="prix" onChange={this.handleInputChange} />
                                { (this.state.errors && "prix" in this.state.errors) ? <p className="text-right text-danger">{this.props.t(`error.${this.state.errors.prix.field}.${this.state.errors.prix.validatorKey}`)}</p> : <p></p> }
                            </Form.Group>
                            <Form.Group controlId="formDescription">
                                <Form.Label>Description</Form.Label>
                                {/* <Form.Control as="textarea" rows="3" name="description" onChange={ this.handleInputChange } /> */}
                                <Card border="secondary" style={{ border: '1px solid gray' }}>
                                    <Card.Body>
                                        <CKEditor
                                            editor={ InlineEditor }
                                            data={this.state.description}
                                            config={ {
                                                // toolbar: [ 'heading', '|', 'strikethrough', 'underline','code', 'codeBlock' , 'bold', 'italic', 'underline', 'strike' ,'link', 'bulletedList', 'numberedList', '-', 'blockQuote' ]
                                                toolbar: {
                                                    items: [
                                                        'heading', '|',
                                                        // 'fontfamily', 'fontsize', '|',
                                                        // 'alignment', '|',
                                                        // 'fontColor', 'fontBackgroundColor', '|',
                                                        'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
                                                        'link', '|',
                                                        // 'outdent', 'indent', '|',
                                                        'bulletedList', 'numberedList', 'todoList', '|',
                                                        // 'code', 'codeBlock', '|',
                                                        // 'insertTable', '|',
                                                        // 'imageUpload', 'blockQuote', '|',
                                                        'undo', 'redo'
                                                    ],
                                                    shouldNotGroupWhenFull: true
                                                }
                                            } }
                                            onReady={ editor => {
                                                // You can store the "editor" and use when it is needed.
                                                // console.log( 'Editor is ready to use!', editor );
                                            } }
                                            onChange={ ( event, editor ) => {
                                                const data = editor.getData();
                                                // console.log( { event, editor, data } );
                                                this.state.description = data;
                                            } }
                                            onBlur={ ( event, editor ) => {
                                                // console.log( 'Blur.', editor );
                                            } }
                                            onFocus={ ( event, editor ) => {
                                                // console.log( 'Focus.', editor );
                                            } }
                                        />
                                    </Card.Body>
                                </Card>
                            </Form.Group>
                            <Form.Group controlId="formUrl">
                                <Form.Label>URL</Form.Label>
                                <Form.Control type="text" placeholder="URL" name="url" onChange={this.handleInputChange} />
                                { (this.state.errors && "url" in this.state.errors) ? <p className="text-right text-danger">{this.props.t(`error.${this.state.errors.url.field}.${this.state.errors.url.validatorKey}`)}</p> : <p></p> }
                            </Form.Group>
                            <div className="mt-4 text-right">
                                <Button className="mr-2" variant="outline-secondary" onClick={this.handleReset} type="reset">{this.props.t('btn_cancel')}</Button>
                                <Button className="mr-2" variant="outline-dark" type="submit">{this.props.t('btn_save')}</Button>
                            </div>
                        </Form>
                    </div>
                    <SweetAlert
                        show={this.state.show}
                        text="Success"
                        confirmButtonText='OK'
                        type="success"
                        onConfirm={() => this.setState({ show: false })}
                    />
                </div>


            </div>
        );
    }
}

export default withTranslation()(AddEvent);