import React, { Component } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../config.js';
import { Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SweetAlert from 'sweetalert2-react';
import { withTranslation  } from 'react-i18next';
class EditOf extends Component {

    state = {
        nom: "",
        prenom: "",
        organisation: "",
        email: "",
        telephone: ""
    }

    constructor(props, context) {
        super(props, context);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        console.log("id : " + this.props.match.params.id);
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization
        };
        const idUser = this.props.match.params.id;
        axios.get(`${BASE_URL}/api/users/${idUser}`, { headers })
            .then(res => {
                const user = res.data.utilisateur;
                this.setState({
                    id: user.id,
                    nom: user.nom,
                    prenom: user.prenom,
                    organisation: user.organisation,
                    email: user.email,
                    telephone: user.telephone
                });
            })
            .catch(error => {
                console.log(error);
            });
    }

    handleInputChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleSubmit = (id) => e => {
        e.preventDefault();
        if (this.state.nom &&
            this.state.prenom &&
            this.state.organisation &&
            this.state.email &&
            this.state.telephone
        ) {
            const { nom, prenom, organisation, email, telephone } = this.state;
            const Authorization = localStorage.getItem('token');
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': Authorization
            };

            axios.put(`${BASE_URL}/api/users/${id}`, { nom, prenom, organisation, email, telephone }, { headers })
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({ show: true });
                        setTimeout(() => {
                            this.props.history.push("/dashboard/of");
                        }, 2000);
                    }
                })
                .catch(error => {
                    throw (error);
                });
        }
    };

    render() {
        return (
            <div className="col-md-6">
                <h1>Modifier Client</h1>
                <br />
                <Form className="py-3" onSubmit={this.handleSubmit(this.state.id)}>
                    <Form.Group controlId="formLastName">
                        <Form.Label>{this.props.t('nom')}</Form.Label>
                        <Form.Control type="text" placeholder={this.props.t('nom')} value={this.state.nom} name="nom" onChange={this.handleInputChange} />
                        { (this.state.nom) ? <p></p> : <p className="text-right text-danger">{this.props.t('mandatory_field')}</p> }
                    </Form.Group>
                    <Form.Group controlId="formFirstName">
                        <Form.Label>{this.props.t('prenom')}</Form.Label>
                        <Form.Control type="text" placeholder={this.props.t('prenom')} value={this.state.prenom} name="prenom" onChange={this.handleInputChange} />
                        { (this.state.prenom) ? <p></p> : <p className="text-right text-danger">{this.props.t('mandatory_field')}</p> }
                    </Form.Group>
                    <Form.Group controlId="formOrganisatation">
                        <Form.Label>{this.props.t('organisation')}</Form.Label>
                        <Form.Control type="text" placeholder={this.props.t('organisation')} value={this.state.organisation} name="organisation" onChange={this.handleInputChange} />
                        { (this.state.organisation) ? <p></p> : <p className="text-right text-danger">{this.props.t('mandatory_field')}</p> }
                    </Form.Group>
                    <Form.Group controlId="formEmail">
                        <Form.Label>{this.props.t('email')}</Form.Label>
                        <Form.Control type="email" placeholder={this.props.t('email')} value={this.state.email} name="email" onChange={this.handleInputChange} readOnly/>
                    </Form.Group>
                    <Form.Group controlId="formPhone">
                        <Form.Label>{this.props.t('phone')}</Form.Label>
                        <Form.Control type="text" placeholder={this.props.t('phone')} value={this.state.telephone} name="telephone" onChange={this.handleInputChange} />
                        { (this.state.telephone) ? <p></p> : <p className="text-right text-danger">{this.props.t('mandatory_field')}</p> }
                    </Form.Group>
                    <div className="mt-4 text-right">
                        <Link to="/dashboard/of" className="mr-2 btn btn-link">{this.props.t('retour_liste')}</Link>
                        <Button className="mr-2" variant="outline-dark" type="submit">{this.props.t('btn_save')}</Button>
                    </div>

                </Form>
                <SweetAlert
                    show={this.state.show}
                    text="Success"
                    confirmButtonText='OK'
                    type="success"
                    onConfirm={() => this.setState({ show: false })}
                />
            </div>
        );
    }
}

export default withTranslation()(EditOf);