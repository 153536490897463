import React, { Component } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../config.js';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { withTranslation } from 'react-i18next';
import SweetAlert from 'sweetalert2-react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';

class Repartition extends Component {

    state = {
        repartitions: []
    }

    constructor(props, context) {
        super(props, context);
        // this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization,
        };
        // get repartitions
        axios.get(`${BASE_URL}/api/repartition`, {headers})
            .then(res => {
                const repartitions = res.data.repartitions;

                this.setState({ repartitions: repartitions });
            })
            .catch(error => {
                console.log(error);
            });
    }


    render() {
        const actionsFormatter = (cell, row, rowIndex) => {
          return <div className="text-center">
            <Link to={"/dashboard/repartitions/" + cell}>
                <FeatherIcon icon="eye" size="48" color="#36909b" />
            </Link>
        </div>
        };
        const colorCellFormatter = (field, row, rowIndex) => {
            return <span style={{ backgroundColor: field }}>
                    {field}
                </span>
        }

        const cols = [{
                dataField: 'nom',
                text: this.props.t('name'),
                sort: true
            }, {
                dataField: 'is_default',
                text: this.props.t('is_default'),
                formatter: (cell, row, rowIndex) => cell == true ? "True" : "False"
            }, 
            {
                dataField: 'id',
                text: 'Action',
                formatter: actionsFormatter
            },
        ];
        return (
            <div className="col-md-10">
                <h1>{this.props.t('repartitions')}</h1>
                <br />
                <BootstrapTable 
                    keyField='id' 
                    data={ this.state.repartitions } 
                    columns={ cols } 
                    pagination={ paginationFactory() }
                />
                
                <SweetAlert
                    show={this.state.show}
                    text="Success"
                    onConfirm={() => this.setState({ show: false })}
                />
            </div>
        );
    }
}

export default withTranslation()(Repartition);