import React, { Component } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../config.js';
import { Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SweetAlert from 'sweetalert2-react';
import { withTranslation  } from 'react-i18next';
class Profil extends Component {

    state = {
        nom: "",
        prenom: "",
        email: "",
        password: "",
        telephone: "",
        organisation:""
    }

    constructor(props, context) {
        super(props, context);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentDidMount() {
        console.log("id : " + this.props.match.params.idPOS);
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization
        };
        const idUser = localStorage.getItem('id');
        axios.get(`${BASE_URL}/api/users/${idUser}`, { headers })
            .then(res => {
                const user = res.data.utilisateur;
                this.setState({
                    id:user.id,
                    nom: user.nom,
                    prenom: user.prenom,
                    email: user.email,
                    password: "",
                    telephone: user.telephone,
                    organisation: user.organisation
                });
            })
            .catch(error => {
                console.log(error);
            });
    }

    handleInputChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleSubmit = (id) => e => {
        e.preventDefault();
        if (this.state.nom.trim() &&
            this.state.prenom.trim() &&
            this.state.email.trim() &&
            this.state.password.trim() &&
            this.state.telephone.trim()
        ) {
            const { nom, prenom, email, password, telephone, organisation } = this.state;
            const Authorization = localStorage.getItem('token');
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': Authorization
            };

            axios.put(`${BASE_URL}/api/users/${id}`, { nom, prenom, email, password, telephone, organisation }, { headers })
                .then(response => {
                    this.setState({ show: true });
                    axios.get(`${BASE_URL}/api/users/${id}`, { headers })
                        .then(res => {
                            const user = res.data.utilisateur;
                            this.setState({
                                nom: user.nom,
                                prenom: user.prenom,
                                email: user.email,
                                password: "",
                                telephone: user.telephone,
                                organisation: user.organisation
                            });
                        })
                        .catch(error => {
                            console.log(error);
                        });
                })
                .catch(error => {
                    throw (error);
                });
        }
    };

    handleReset = () => {
        this.setState({
            nom: "",
            prenom: "",
            email: "",
            password: "",
            telephone: ""
        });
    };

    render() {
        let isOf = false;
        if (localStorage.getItem("label") === "OF_USER") {
            isOf = true
        }
        return (
            <div className="col-md-6">
                <h1>{this.props.t('my_profil')}</h1>
                <br />
                <Form className="py-3" onSubmit={this.handleSubmit(this.state.id)}>
                    <Form.Group controlId="formLastName">
                        <Form.Label>{this.props.t('nom')}</Form.Label>
                        <Form.Control type="text" placeholder={this.props.t('nom')} value={this.state.nom} name="nom" onChange={this.handleInputChange} required />

                    </Form.Group>
                    <Form.Group controlId="formFirstName">
                        <Form.Label>{this.props.t('prenom')}</Form.Label>
                        <Form.Control type="text" placeholder={this.props.t('prenom')} value={this.state.prenom} name="prenom" onChange={this.handleInputChange} required />
                    </Form.Group>
                    {isOf ?
                        <span>
                            <Form.Group controlId="formOrganisation">
                                <Form.Label>{this.props.t('organisation')}</Form.Label>
                                <Form.Control type="text" placeholder={this.props.t('organisation')} value={this.state.organisation} name="organisation" onChange={this.handleInputChange} required />
                            </Form.Group>
                        </span>
                    :
                        <span></span>
                    }
                    <Form.Group controlId="formEmail">
                        <Form.Label>{this.props.t('email')}</Form.Label>
                        <Form.Control type="email" placeholder={this.props.t('email')} value={this.state.email} name="email" onChange={this.handleInputChange} required />
                    </Form.Group>
                    <Form.Group controlId="formPassword">
                        <Form.Label>{this.props.t('password')}</Form.Label>
                        <Form.Control type="password" placeholder={this.props.t('password')} name="password" value={this.state.password} onChange={this.handleInputChange} required />
                    </Form.Group>
                    <Form.Group controlId="formPhone">
                        <Form.Label>{this.props.t('phone')}</Form.Label>
                        <Form.Control type="text" placeholder={this.props.t('phone')} value={this.state.telephone} name="telephone" onChange={this.handleInputChange} />
                    </Form.Group>
                    <div className="mt-4 text-right">
                        <Link to="/dashboard/profil" className="mr-2 btn btn-outline-secondary">{this.props.t('btn_cancel')} </Link>
                        <Button className="mr-2" variant="outline-dark" type="submit">{this.props.t('btn_save')}</Button>
                    </div>

                </Form>
                <SweetAlert
                    show={this.state.show}
                    text="Modification avec succès"
                    confirmButtonText='OK'
                    type="success"
                    onConfirm={() => this.setState({ show: false })}
                />
            </div>
        );
    }
}

export default withTranslation()(Profil);