import React, { Component } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../config.js';
import { withTranslation } from 'react-i18next';
class Web extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            configs: [],
            file: ""
        };
        this.handleChange = this.handleChange.bind(this);

    }

    componentDidMount() {
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization,
        };
        //get config screen
        axios.get(`${BASE_URL}/api/config`, { headers })
            .then(res => {
                if (res.data.status === "success") {
                    const configs = res.data.config;
                    // const tab = [];
                    // tab = ['bg', 'bg_mobile'];
                    // configs.forEach((item) => {
                    //     if( item.key ){
                    //         this.setState({ configs: configs });
                    //     }
                    // });
                    this.setState({ configs: configs });
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    handleChange = (key) => (e) => {
        e.preventDefault();


        let param = new FormData();
        param.append("key", key);
        param.append("file", e.target.files[0]);

        const Authorization = localStorage.getItem('token');
        const headers = {
            'Authorization': Authorization
        };
        console.log(e.target.files[0]);
        axios.post(`${BASE_URL}/api/config/upload`, param, { headers })
            .then(response => {
                axios.get(`${BASE_URL}/api/config`, { headers })
                    .then(res => {
                        const configs = res.data.config;
                        this.setState({ configs: configs });
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(error => {
                throw (error);
            });
    }

    render() {
        return (
            <section>
            <div className="col-md-12">
                <h1>{this.props.t('settings')}</h1>
                <br />
                <div className="d-flex">
                    {this.state.configs.map((config, index) => 
                        (config.key === 'bg_mobile' || config.key === 'splash_screen_mobile') ?
                                <div className="card w-25 mx-1 py-2" key={ index }>
                                    <img className="card-img-top" src={BASE_URL + '/' + config.value} alt="Empty"/>
                                    <div className="card-body">
                                        {
                                            (config.key === "bg_mobile") ?
                                                <p>Cover ( {this.props.t('horizontal_format')}: 1024 px x 768 px )</p>
                                                :
                                                (config.key === "splash_screen_mobile") ?
                                                    <p>Splash screen ( 1920 px x 1080 px )</p>
                                                    :
                                                    <p>{config.key}</p>
                                        }
                                        <div className="custom-file" id="customFile">
                                            <input className="custom-file-input" type="file" accept="image/*" name="file" onChange={this.handleChange(config.key)} />
                                            <label className="custom-file-label">
                                                {this.props.t('input_image')}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            
                        : 
                        <div key={ index }></div>
                                
                        
                    )}
                </div>
            </div>
            </section>
        );
    }
}

export default withTranslation()(Web);