import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../assets/css/login.css';
import { BASE_URL } from '../config';
import Moment from 'react-moment';
import FeatherIcon from 'feather-icons-react';

class History extends Component {
    state = {
        histories: []
    };
    constructor(props, context) {
        super(props, context);

    }
    getHistory() {
        const x_id = localStorage.getItem('x-idBenf');
        axios.get(`${BASE_URL}/api/mobile/history/${x_id}`)
            .then(res => {
                const histories = res.data.data
                this.setState({
                    histories: histories,
                })

                console.log('History ', this.state.histories);
            })
            .catch(error => {
                throw (error);
            })
    }
    componentDidMount() {
        this.getHistory();
    }


    render() {
        return (
            <section className="main">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-5 col-10">
                            <h1 className="text-center my-3">
                                <a href="#/webui" className="text-primary">WEB UI (dataviz)</a>
                            </h1>
                            <div className="d-flex justify-content-between  align-items-center my-5">
                                <a href="#/webui/beneficiaires" className="text-primary">
                                    <FeatherIcon icon="arrow-left" />&nbsp;
                                    Retour liste Bénéficiaires
                                </a>
                                <h3 className="mb-0">History</h3>
                            </div>
                            <div className="my-5 table-responsive">
                                <table className="table table-bordered table-hover">
                                    <thead style={{ backgroundColor : 'rgba(226, 99, 38, 0.85)' }}>
                                        <tr>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.histories.map((history, i) =>
                                            <tr key={i}>
                                                <td>{history.amount}</td>
                                                <td>
                                                <Moment format="D MMM YYYY HH:mm" withTitle>
                                                    {history.date}
                                                </Moment>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default History;
