import React, { Component } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../config.js';
import FeatherIcon from 'feather-icons-react';
import SweetAlert from 'sweetalert2-react';
import { withTranslation  } from 'react-i18next';
import { Link } from 'react-router-dom';
class Organisateur extends Component {
    
    state = {
        users: [],
        text_alert: ""
      }
      
      constructor (props,context){
        super(props,context);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleRevertDemo = this.handleRevertDemo.bind(this);

    }
    
      componentDidMount() {
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization,
          };
        axios.get(`${BASE_URL}/api/users/of`, {headers})
          .then(res => {
            const users = res.data.utilisateurs;
            this.setState({ users:users });
          });
      }
      handleInputChange = e => {
        this.setState({
          [e.target.name]: e.target.value
        });
      };

      handleSubmit = (id,state) => (e) => {
            const Authorization = localStorage.getItem('token');
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': Authorization
              };
              if(state === true){
                  state = 'actif';
                  this.setState({ text_alert : `${this.props.t('user_actif')}` });
              }else {
                  state = 'inactive';
                  this.setState({ text_alert : `${this.props.t('user_inactif')}` });
              }
              console.log(`${BASE_URL}/api/users/${id}/state/${state}`);
            axios.put(`${BASE_URL}/api/users/${id}/state/${state}`, {}, {headers})
            .then(response => {
                this.setState({ show: true });
                this.props.history.push("/dashboard/of");
                axios.get(`${BASE_URL}/api/users/of`, {headers})
                    .then(res => {
                        const users = res.data.utilisateurs;
                        this.setState({ users:users});
                    });
            })
            .catch(error => {
                throw(error);
            });
    };

    handleRevertDemo = (id) => (e) => {
            const Authorization = localStorage.getItem('token');
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': Authorization
              };
                this.setState({ text_alert : `${this.props.t('user_revert_demo')}` });
              console.log(`${BASE_URL}/api/users/${id}/demo`);
            axios.put(`${BASE_URL}/api/users/${id}/demo`, {}, {headers})
            .then(response => {
                this.setState({ show: true });
                this.props.history.push("/dashboard/of");
                axios.get(`${BASE_URL}/api/users/of`, {headers})
                    .then(res => {
                        const users = res.data.utilisateurs;
                        this.setState({ users:users});
                    });
            })
            .catch(error => {
                throw(error);
            });
    };

    render() {
        return (
            <div className="col-md-12">
                <h1>{this.props.t('clients')}</h1>
                <br />
                <div className="table-responsive">
                    <table className="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th scope="col">{this.props.t('nom')}</th>
                                <th scope="col">{this.props.t('prenom')}</th>
                                <th scope="col">{this.props.t('email')}</th>
                                <th scope="col">{this.props.t('organisation')}</th>
                                <th scope="col">{this.props.t('phone')}</th>
                                <th scope="col">{this.props.t('state')}</th>
                                <th scope="col">{this.props.t('demo')}</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        { this.state.users.map((user, index) =>
                            <tr key={ index }>
                                <td>{user.nom}</td>
                                <td>{user.prenom}</td>
                                <td>{user.email}</td>
                                <td>{user.organisation}</td>
                                <td>{user.telephone}</td>
                                <td className="text-center">
                                    {user.actif ? <FeatherIcon icon="user-check" size="28" color="#36909b" /> : <FeatherIcon icon="user-x" size="28" color="#f36b21"/> } 
                                </td>
                                <td className="text-center">
                                    {user.label == 'DEMO_USER' ? <FeatherIcon icon="check-square" size="28" color="#212529"/> : <FeatherIcon icon="square" size="28" color="#212529"  onClick={this.handleRevertDemo(user.id)}/> } 
                                </td>
                                <td>
                                <div className="d-flex justify-content-around align-items-center">
                                    <Link to={"/dashboard/of/" + user.id}>
                                        <FeatherIcon icon="eye" size="48" color="#000" />
                                    </Link>
                                    { <button className="btn btn-outline-dark" onClick={this.handleSubmit(user.id,!user.actif)}> {user.actif ? `${this.props.t('disable')}` : `${this.props.t('enable')}`} </button>} 
                                </div>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>

                <SweetAlert
                    show={this.state.show}
                    text={this.state.text_alert}
                    confirmButtonText='OK'
                    type="success"
                />
                
                </div>
        );
    }
}

export default withTranslation()(Organisateur);