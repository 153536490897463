import React, { Component } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../config.js';
import { Button, Form, Row, Col } from 'react-bootstrap';
import SweetAlert from 'sweetalert2-react';
import Select, {components} from 'react-select';
import { withTranslation  } from 'react-i18next';

const IndicatorsContainer = (props) => {
    return (
      <div>
        <components.IndicatorsContainer {...props}/>
      </div>
    );
  }
class AddItem extends Component {

    state = {
        pos: [],
        items: [],
        repartitions: [],
        labelItem: "",
        descriptionItem: "",
        POS_id: "",
        item_id: "",
        options: [],
        DetailPOS: {},
        errors: {},
        repartitionDisabled: {},
        hasDefaultBeneficiary: true
    }

    constructor(props, context) {
        super(props, context);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleConsign = this.handleConsign.bind(this);

    }
    handleChange = (val) => {
        this.setState({pos: val});

    }
    componentDidMount() {
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization,
        };
        axios.get(`${BASE_URL}/api/pos?type=type`, { headers })
            .then(res => {
                const items = res.data.pos;
                let options = items.map(function(item) {
                    return {
                        value: item.idPOS,
                        label: item.labelPOS,
                    };
                });
                this.setState({options: options});
            })
            .catch(error => {
                console.log(error);
            });
        // get repartitions
        axios.get(`${BASE_URL}/api/repartition`, { headers })
            .then(res => {
                const repartitions = res.data.repartitions;
                this.setState({
                    repartitions: repartitions,
                });
            })
            .catch(error => {
                console.log(error);
            });
            // get default beneficiaire
        axios.get(`${BASE_URL}/api/beneficiaires/default`, { headers })
            .then(res => {
                if (res.data.beneficiares && res.data.beneficiares.length == 0) {
                    this.setState({
                        hasDefaultBeneficiary: false
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
    }


    handleConsign = e => {
        this.setState({
            consigne: e.target.checked
        });
    }

    handleInputChange (e) {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleSelectChange = (_pos) => (e) => {
        e.preventDefault();
        let ResPOS = Object.assign( {}, this.state.DetailPOS );
        let repartitionDisabled = Object.assign( {}, this.state.repartitionDisabled );
        let key = Object.keys(ResPOS);
        if (!key.includes(_pos.value.toString())){
            ResPOS[_pos.value] = {};
        }
        // special rule for consigne field
        if (e.target.name == "consigne" && (e.target.value == 1 || e.target.value == '1' ) ) {
            // ResPOS[_pos.value]['repartition_id'] = '';
            repartitionDisabled[_pos.value] = 1;
        } else {
            delete repartitionDisabled[_pos.value];
        }
        ResPOS[_pos.value][e.target.name] = e.target.value;
        this.setState({
            DetailPOS: ResPOS,
            repartitionDisabled: repartitionDisabled
        });
        // console.log('ResPOS >> ', ResPOS);
    };

    handleSubmit = e => {
        e.preventDefault();

        const { labelItem, descriptionItem, hasDefaultBeneficiary } = this.state;
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization
        };
        this.setState({
            errors: {},
            repartitionDisabled: {},
        });
        let ResPOS = Object.assign( {}, this.state.DetailPOS );

        const pItemsErrors = {};
        const pItemsRepartitions = {};
        if (this.state.pos.length > 0 ) {
            //check PItem fields
                for (const posId in ResPOS) {
                    const pItem = ResPOS[posId];
                    if (!('prixItem' in pItem)) {
                        const fieldKey = posId+'-pItem-prixItem';
                        pItemsErrors[fieldKey] = {field: posId+'-pItem-prixItem', validatorKey: "isNotNull"};
                    }
                    if (!('stockItem' in pItem)) {
                        const fieldKey = posId+'-pItem-stockItem';
                        pItemsErrors[fieldKey] = {field: posId+'-pItem-stockItem', validatorKey: "isNotNull"};
                    }
                    if (!('repartition_id' in pItem) && hasDefaultBeneficiary == false && (pItem.consigne == 0 || pItem.consigne == '0' )) {
                        console.log('Repartition can not be empty. Default Beneficiary is missing for the festival');
                        /**
                         * Attention there is no Default Beneficiaire set for the OF user. The user MUST select a repartition
                         * otherwise there is a repartition risk when selling items
                         */
                        const fieldKey = posId+'-pItem-repartition_id';
                        pItemsErrors[fieldKey] = {field: posId+'-pItem-repartition_id', validatorKey: "isNotNull"};
                    }
                    if (('repartition_id' in pItem) && (pItem.consigne == 1|| pItem.consigne == '1' )) {
                        console.log('Repartition will be ignored');
                        pItemsRepartitions[posId] = pItem;
                    }
                }
                this.setState({errors: pItemsErrors})
                this.setState({repartitionDisabled: pItemsRepartitions})
        }

        if (this.state.pos.length < 1 ) {
            this.setState({errors: { pos: {field: "pos", validatorKey: "isNotNull"}}});
            console.log(this.state.errors);
            throw 'Data Missing';
        }
        if (Object.keys(pItemsErrors).length > 0) {
            console.log(this.state.errors);
            throw 'Data Missing';
        }
        // items
        axios.post(`${BASE_URL}/api/items/`, { labelItem, descriptionItem }, { headers })
            .then(response => {
                if (response.data.status === 'success') {
                    const item_id = response.data.data.idItem;
                    this.setState({ item_id: item_id });
                    // pitem

                    let ResPOS = Object.assign( {}, this.state.DetailPOS );
                    // if (ResPOS.length)
                    let key = Object.keys(ResPOS);
                    let promise = [];
                    this.state.pos.forEach(_pos => {
                        if ( key.includes(_pos.value.toString()) ){
                            let params = {
                                prixItem: ResPOS[_pos.value]["prixItem"],
                                stockItem: ResPOS[_pos.value]["stockItem"],
                                repartition_id: (ResPOS[_pos.value]["consigne"] == '1' || ResPOS[_pos.value]["consigne"] == 1) ? '' : ResPOS[_pos.value]["repartition_id"],
                                consigne: ResPOS[_pos.value]["consigne"]
                            }
                            promise.push(axios.post(`${BASE_URL}/api/items/${item_id}/pos/${_pos.value}`, params , { headers }));
                        }
                        
                    });
                    Promise.all(promise)
                    .then(response => {
                        // console.log('response pitems >> ', response);
                        this.setState({ 
                            show: true,
                            pos: [],
                            labelItem: "",
                            descriptionItem: ""
                        });
                        this.props.history.push("/dashboard/items");
                    })
                    .catch(error => {
                        console.log('throw error pitems >> ', error);
                        throw (error);
                    });
                } else {
                    console.log('item KO');
                    if (response.data.errors) {
                        this.setState({ errors: response.data.errors });
                    }
            }
                
            })
            .catch(error => {
                console.log('throw error items >> ', error);
                throw (error);
            });
    };

    render() {
        return (
            <div className="col-md-12">
                <h1>{this.props.t('add_item')}</h1>                
                <div className="row justify-content-left">
                    <div className="col-md-12">
                        <Form className="py-3" onSubmit={this.handleSubmit}>
                            <div className="row">
                                <div className="col-md-4">
                                    <Form.Group controlId="formLabel">
                                        <Form.Label>{ this.props.t('forms.items.label') }</Form.Label>
                                        <Form.Control type="text" placeholder="Label" name="labelItem" onChange={this.handleInputChange} required />
                                        { (this.state.errors && "labelItem" in this.state.errors) ? <p className="text-right text-danger">{this.props.t(`error.${this.state.errors.labelItem.field}.${this.state.errors.labelItem.validatorKey}`)}</p> : <p></p> }
                                    </Form.Group>
                                    <Form.Group controlId="formDescription">
                                        <Form.Label>{ this.props.t('forms.items.description') }</Form.Label>
                                        <Form.Control as="textarea" rows="3" name="descriptionItem" onChange={this.handleInputChange} />
                                    </Form.Group>
                                    <Form.Group controlId="formPOS">
                                        <Form.Label>POS</Form.Label>
                                        <Select
                                            async
                                            withAll={true}
                                            closeMenuOnSelect={false}
                                            options={this.state.options}
                                            components={IndicatorsContainer}
                                            placeholder={this.props.t('choose_pos')}
                                            isMulti
                                            onChange={this.handleChange}
                                        />
                                        { (this.state.errors && 'pos' in this.state.errors) ? <p className="text-right text-danger">{this.props.t(`error.msg.notNull`)}</p> : <p></p> }
                                    </Form.Group>
                                </div>
                                <div className="col-md-8">
                                    {this.state.pos.map((_pos, i) =>

                                        <Form.Group key={ i } controlId="formPOS">
                                            <Form.Label>POS {_pos.label}</Form.Label>
                                            <Row>
                                                <Col>
                                                    <Form.Control type="number" isInvalid={!!(this.state.errors && _pos.value+'-pItem-prixItem' in this.state.errors)}  step="0.001" placeholder={this.props.t('prix')+' '+_pos.label} name="prixItem" onChange={this.handleSelectChange(_pos)} />
                                                    { (this.state.errors && _pos.value+'-pItem-prixItem' in this.state.errors) ? <p className="text-right text-danger">{this.props.t(`error.msg.notNull`)}</p> : <p></p> }
                                                    <p className="text-small text-left text-muted"><i>{ this.props.t('forms.items.price_item_desc') }</i></p>
                                                </Col>
                                                <Col>
                                                    <Form.Control type="number" isInvalid={!!(this.state.errors && _pos.value+'-pItem-stockItem' in this.state.errors)} placeholder={this.props.t('stock')+' '+_pos.label}  name="stockItem" onChange={this.handleSelectChange(_pos)} />
                                                    { (this.state.errors && _pos.value+'-pItem-stockItem' in this.state.errors) ? <p className="text-right text-danger">{this.props.t(`error.msg.notNull`)}</p> : <p></p> }
                                                </Col>
                                                <Col>
                                                    <Form.Control as="select" name="repartition_id" onChange={this.handleSelectChange(_pos)} >
                                                        <option value="">{ this.props.t('choose_repartition') }</option>
                                                        {this.state.repartitions.map((repartition, j) =>
                                                            <option key={ j } value={repartition.id}>{repartition.nom}</option>
                                                            )}
                                                    </Form.Control>
                                                    { (this.state.errors && _pos.value+'-pItem-repartition_id' in this.state.errors) ? <p className="text-right text-danger">{this.props.t(`error.msg.notNull`)}</p> : <p></p> }
                                                    { (this.state.repartitionDisabled && _pos.value in this.state.repartitionDisabled) ? <p className="text-right text-danger">{this.props.t(`error.repartition.ignore`)}</p> : <p></p> }
                                                </Col>
                                                {/* <Col>
                                                    <Form.Group controlId="formConsign">
                                                        <Form.Check type="checkbox" onChange={this.handleSelectChange(_pos)} name="consigne" label={this.props.t('consign')} />
                                                    </Form.Group>
                                                </Col> */}
                                                <Col>
                                                    <Form.Control as="select" defaultValue="0" name="consigne" onChange={this.handleSelectChange(_pos)} required>
                                                        <option value="1">{ this.props.t('forms.pitems.deposit') }</option>
                                                        <option value="0">{ this.props.t('forms.pitems.non_deposit') }</option>
                                                    </Form.Control>
                                                </Col>
                                            </Row>
                                        </Form.Group>

                                    )}
                                </div>
                            </div>
                            <div className="mt-4 text-left">
                                <Button className="mr-2" variant="outline-secondary" onClick={this.handleReset} type="reset">{this.props.t('btn_cancel')}</Button>
                                <Button className="mr-2" variant="outline-dark" type="submit">{this.props.t('btn_save')}</Button>
                            </div>
                        </Form>
                    </div>
                </div>
                <SweetAlert
                    show={this.state.show}
                    text="Insertion avec succès"
                    confirmButtonText='OK'
                    type="success"
                    onConfirm={() => this.setState({ show: false })}
                />
            </div>
        );
    }
}

export default withTranslation()(AddItem);