import React, { Component, useRef, useState, useEffect } from "react";
import { withTranslation  } from 'react-i18next';
import axios from 'axios';
import { BASE_URL } from '../../../config.js';
import DatePicker from "react-datepicker";
import FeatherIcon from 'feather-icons-react';
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/es/index";
import Select, {components} from 'react-select';
import moment from 'moment';
import Moment from 'react-moment';
// import { Line } from 'react-chartjs-2';
import Chart from "react-apexcharts";
import { CSVLink, CSVDownload } from "react-csv";
import { Tabs, Tab } from 'react-bootstrap'
import DashboardFilter from './dashboardFilter';

class Dashboard extends Component {
    constructor(props, context) {
        super(props, context);
        this.handleDateFromChange = this.handleDateFromChange.bind(this);
        this.handleDateToChange = this.handleDateToChange.bind(this);
        this.handlePosChange = this.handlePosChange.bind(this);
        this.getGraphTransactionsLabels = this.getGraphTransactionsLabels.bind(this);
        this.getGraphTransactionsValues = this.getGraphTransactionsValues.bind(this);
        this.setBeneficiariesDonationArray = this.setBeneficiariesDonationArray.bind(this);
        this.buildItemSalesData = this.buildItemSalesData.bind(this);
        this.handleDownloadStatsCsv = this.handleDownloadStatsCsv.bind(this);
        this.handleDownloadItemSalesCsv = this.handleDownloadItemSalesCsv.bind(this);
        this.handleCountTotalItemsSales = this.handleCountTotalItemsSales.bind(this);
    }
    state = {
        stats: {},
        beneficiaires: [],
        posOptions: [],
        donations: [],
        donationArray: {},
        filterDateFrom: null,
        filterDateTo: null,
        filterPos: '',
        selectedFilterPos: {},
        graphTransactionsLabels: [],
        graphTransactionsValues: [],
        itemSalesData: [],
        countTotalItemsSales: 0,
        statsCsvData: [],
        walletsCsvData: [],
        itemSalesCsvData: [],
    }

    handleDateFromChange(date) {
        this.setState({
            filterDateFrom: date
        });
    }
    handleDateToChange(date) {
        this.setState({
            filterDateTo: date
        });
    }
    handlePosChange = (val) => {
        this.setState({filterPos: val});
        this.setState({selectedFilterPos: val});
    }
    componentDidMount() {
        const Authorization = localStorage.getItem('token');
        // if (!localStorage.getItem('token')) {
        //     window.location.reload();
        // }
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization,
        };
        console.log(headers);
        // get stats for dashboard
        axios.post(`${BASE_URL}/api/stats/dashboard`, {  }, {headers})
            .then(res => {
                if (res.data.status == "success") {
                    this.setState({ stats: res.data.data });
                    this.getGraphTransactionsLabels(res.data.data);
                    this.getGraphTransactionsValues(res.data.data);
                }
            })
            .then(() => {
                // group item sales by Item
                this.buildItemSalesData();
                this.handleCountTotalItemsSales();
                //set csv data
                this.handleDownloadStatsCsv();
                this.handleDownloadItemSalesCsv();
            })
            .catch(error => {
                console.log(error);
            });

        axios.get(`${BASE_URL}/api/pos?type=type`, {headers})
        .then(res => {
          const pos = res.data.pos;
          let options = pos.map(function(item) {
              return {
                  value: item.idPOS,
                  label: item.labelPOS,
              };
          });
          this.setState({posOptions: options});
        })
        .catch(error => {
            console.log(error);
        });

        // get beneficiaires
        axios.get(`${BASE_URL}/api/beneficiaires`, {headers})
            .then(res => {
                const beneficiaires = res.data.beneficiares;

                this.setState({ beneficiaires: beneficiaires });
            }).then(() => {
                // get donations
                axios.get(`${BASE_URL}/api/donations`, {headers})
                .then(res => {
                    const donations = res.data.donations;
                    this.setState({ donations: donations });
                    this.setBeneficiariesDonationArray(donations);
                })
                .catch(error => {
                    console.log(error);
                });
            })
            .then(() => {
                this.handleDownloadWalletsCsv();
            })
            .catch(error => {
                console.log(error);
            });
    }

    handleClear = e => {
        this.setState({
            filterDateFrom: null,
            filterDateTo: null,
            filterPos: '',
            selectedFilterPos: {},
        });
    }
    buildItemSalesData = e => {
        // data are by pItems we need to group them by Item and show the POS
        const data = {};
        this.state.stats.countItemsSales.map((pItem, index) => {
            //check if data by item exist if not set the array
            if (!data[pItem.idItem]) {
                data[pItem.idItem] =  {
                    pItems: [],
                    total: 0,
                    totalSold: 0,
                    labelPOS: pItem.labelPOS,
                    labelItem: pItem.labelItem,
                    idItem: pItem.idItem
                }
            } else if (data[pItem.idItem] && !data[pItem.idItem].pItems) {
                data[pItem.idItem].pItems = [];
            }
            data[pItem.idItem].pItems.push(pItem);
            data[pItem.idItem].total = data[pItem.idItem].total + (parseFloat(pItem.totalSold * pItem.unitPrice));
            data[pItem.idItem].totalSold = data[pItem.idItem].totalSold + 1;
        });
        const productArray = [];
        for (const key in data) {
            const itemData = data[key];
            let isDeposit = true;
            itemData.pItems.map((pitem) => {
                if(!pitem.consigne || pitem.consigne === false) {
                    isDeposit = false;
                }
            });
            itemData.isDeposit = isDeposit;
            productArray.push(itemData)
        }
        this.setState({ itemSalesData: productArray });
        // calculate total by Item
        // let tr = [];
        // if (data) {
        //     console.log('this.state.itemSalesData',data);
        //     for (const key in data) {
        //         const itemData = data[key];
        //         console.log('data[key]',itemData);
        //         tr.push(
        //             <tr key={key}>
        //                 <table class="table">
        //                 <thead>
        //                         <tr>
        //                             <th><h2>{itemData.labelItem}</h2></th>
        //                         </tr>
        //                     </thead>
        //                     <tr>
        //                         <td>
        //                             <table class="table">
        //                                 <thead>
        //                                     <tr>
        //                                         <th class="col-4">{this.props.t('stats.label_pos')}</th>
        //                                         <th class="col-2">{this.props.t('stats.unit_price')}</th>
        //                                         <th class="col-2">{this.props.t('stats.total_sold')}</th>
        //                                         <th class="col-2">{this.props.t('stats.total')}</th>
        //                                         <th class="col-2">{this.props.t('stats.is_deposit')}</th>
        //                                     </tr>
        //                                     {itemData.pItems && itemData.pItems.map((pItem, index) => {
        //                                         return <tr>
        //                                             <td>
        //                                                 {pItem.labelPOS}<br/>
        //                                                 <i>
        //                                                     <Moment format="DD/MM/YYYY HH:mm:ss" withTitle>
        //                                                         {pItem.updatedAt}
        //                                                     </Moment>
        //                                                 </i>
        //                                             </td>
        //                                             <td>{pItem.unitPrice}</td>
        //                                             <td>{pItem.totalSold}</td>
        //                                             <td>{parseFloat(pItem.totalSold * pItem.unitPrice).toFixed(2)}</td>
        //                                             <td>{pItem.consigne && <FeatherIcon icon="check" size="28" color="#36909b"/> || ''}</td>
        //                                         </tr>
        //                                     })}
        //                                 </thead>
        //                             </table>
        //                         </td>
        //                     </tr> 
        //                 </table>
        //             </tr>
        //         )
        //     }
        // }
    }
    handleCountTotalItemsSales = e => {
        // data are by pItems we need to group them by Item and show the POS
        let total = 0;
        this.state.stats.countItemsSales.map((pItem, index) => {
            total = total + parseFloat(pItem.unitPrice) * parseFloat(pItem.totalSold);
        });
        this.setState({ countTotalItemsSales: total });
    }
    handleDownloadStatsCsv = e => {
        // get stats
        const data = [
            { 
                [this.props.t('stats.totalTransactionsAmount')]: parseFloat(this.state.stats.totalTransactionsAmount).toFixed(2), 
                [this.props.t('stats.countTransactions')]: parseFloat(this.state.stats.countTransactions), 
                [this.props.t('stats.countEmailLeads')]: parseFloat(this.state.stats.countEmailLeads), 
                [this.props.t('stats.countQrcodesTransactions')]: parseFloat(this.state.stats.countQrcodesTransactions)
            }
          ];
        this.setState({ statsCsvData: data });
    }
    handleDownloadWalletsCsv = e => {
        const data = [];
        this.state.beneficiaires.map((value, index) => {
            data.push({
                    [this.props.t('beneficiaire')] : value.nom,
                    [this.props.t('stats.totalAmountSpent')] : parseFloat(value.montant).toFixed(2),
                    [this.props.t('stats.donations')] : this.state.donationArray[value.id] && parseFloat(this.state.donationArray[value.id]).toFixed(2) || 0,
                });
          });
        this.setState({ walletsCsvData: data });
    }

    handleDownloadItemSalesCsv = e => {
        const data = [];
        this.state.stats.countItemsSales.map((value, index) => {
            data.push({
                    [this.props.t('stats.label_item')] : value.labelItem,
                    [this.props.t('stats.label_pos')] : value.labelPOS,
                    [this.props.t('stats.total_sold')] : value.totalSold || value.count,
                    [this.props.t('stats.unit_price')] : value.unitPrice || undefined,
                    [this.props.t('stats.total')] : value.unitPrice && (parseFloat(value.unitPrice) * parseFloat(value.totalSold)) || undefined,
                    [this.props.t('stats.is_deposit')] : value.consigne || undefined,
                    [this.props.t('stats.updated_at')] : value.updatedAt || undefined
                });
          });
        this.setState({ itemSalesCsvData: data });
    }

    handleSubmit = e => {
        e.preventDefault();

        const { filterDateFrom, filterDateTo, filterPos } = this.state;
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization
        };
        // get dates
        const dateFrom = moment(filterDateFrom);
        const dateTo = moment(filterDateTo);
        let posId = null;
        if (filterPos && filterPos.value) {
            posId = filterPos.value;
        }
        axios.post(`${BASE_URL}/api/stats/dashboard`, { filter: { from: dateFrom, to: dateTo, pos: posId } }, {headers})
            .then(res => {
                console.log('res', res);
                if (res.data.status == "success") {
                    this.setState({ stats: res.data.data });
                    this.getGraphTransactionsLabels(res.data.data);
                    this.getGraphTransactionsValues(res.data.data);
                }
            })
            .then(() => {
                // group item sales by Item
                this.buildItemSalesData();
                this.handleCountTotalItemsSales();
                //set csv data
                this.handleDownloadStatsCsv();
                this.handleDownloadItemSalesCsv();
            })
            .catch(error => {
                console.log(error);
            });

    };
    getGraphTransactionsLabels = (stats) => {
        const data = stats.transactionsByMinute.map(function (item) {
            return item.date;
          });
        this.setState({ graphTransactionsLabels: data });
    }
    getGraphTransactionsValues = (stats) => {
        const data = stats.transactionsByMinute.map(function (item) {
            return item.count;
          });
        this.setState({ graphTransactionsValues: data });
    }
    setBeneficiariesDonationArray = (donations) => {
        const data = {};
        donations.map(function (donation) {
            donation.DonationCarts.map(function (donationCart) {
                if (data[donationCart.beneficiaire_id]) {
                    data[donationCart.beneficiaire_id] += parseFloat(donationCart.amount);
                } else {
                    data[donationCart.beneficiaire_id] = parseFloat(donationCart.amount);
                }
            })
          });
        this.setState({ donationArray: data });
    }

    render() {
        const options = {
            chart: {
                id: 'chart2',
                type: 'line',
                height: 230,
                toolbar: {
                    autoSelected: 'pan',
                    show: false
                }
            },
            colors: ['#6F97CC'],
            stroke: {
              width: 3
            },
            dataLabels: {
              enabled: false
            },
            markers: {
              size: 0
            },
            xaxis: {
              type: 'datetime',
              labels: {
                datetimeUTC: false,
              },
              datetimeUTC: false
            }
        }
        const series = [
          {
            name: "transactions",
            // data: this.state.stats.transationsTimeSeries
            data: this.state.stats.transationsTimeSeries
          }
        ];
        const seriesLine = [{
            data: this.state.stats.transationsTimeSeries
        }];
        const optionsLine = {
          chart: {
            id: 'chart1',
            height: 130,
            type: 'area',
            brush:{
              target: 'chart2',
              enabled: true
            },
            selection: {
              enabled: true,
            //   xaxis: {
            //     min: new Date(firstDate).getTime(),
            //     max: new Date(lastDate).getTime()
            //   }
            },
          },
          colors: ['#F3715A'],
          fill: {
            type: 'gradient',
            gradient: {
              opacityFrom: 0.91,
              opacityTo: 0.1,
            }
          },
          xaxis: {
            type: 'datetime',
            labels: {
              datetimeUTC: false,
            },
            tickAmount: 6,
            tooltip: {
              enabled: true
            },
            datetimeUTC: false
          },
          yaxis: {
            tickAmount: 2
          }
        }
        const data = {
            labels: this.state.graphTransactionsLabels,
            datasets: [
              {
                label: this.props.t('stats.transactionsGraphDatasetTitle'),
                data: this.state.graphTransactionsValues,
                fill: false,
                backgroundColor: '#F3715A',
                borderColor: '#F3715A',
              },
            ],
          };
          
        //   const options = {
        //     animations: {
        //       radius: {
        //         duration: 400,
        //         easing: 'linear',
        //         loop: (context) => context.active
        //       }
        //     },
        //     hoverRadius: 12,
        //     hoverBackgroundColor: '#6F97CC',
        //     interaction: {
        //       mode: 'nearest',
        //       intersect: false,
        //       axis: 'x'
        //     },
        //     scales: {
        //       yAxes: [
        //         {
        //           type: 'logarithmic',
        //           ticks: {
        //             beginAtZero: true,
        //             stepSize: 1
        //           },
        //         },
        //       ],
        //     },
        //   };
          
        return (
            <div className="col-md-12">
            <div className="row ">
            <h1>{this.props.t('dashboard')}</h1>
            <br />
                <div className="col-md-12">
                <Tabs defaultActiveKey="income" id="uncontrolled-tab-example">
                    <Tab eventKey="income" title={this.props.t('stats.income')}>
                        <div className="row stats py-5">
                                <div className="col-md-12 col-sm-12">
                                    <div className="row stats">
                                        <div className="col-lg-4 col-md-5 col-sm-12">
                                            <div class="card shadow p-3 mb-5 bg-white rounded">
                                                <div class="card-body">
                                                <h5 class="card-title">{this.props.t('stats.wallets')}</h5>
                                                <ul class="list-group">
                                                    {this.state.beneficiaires.map((value, index) => {
                                                        // return <li key={index}>{value}</li>
                                                        return <li key={index} class="list-group-item list-group-item-action flex-column align-items-start">
                                                            <div class="d-flex w-100 justify-content-between">
                                                                <h5 class="mb-1">{value.nom} </h5>
                                                                <h3>
                                                                    <span class="badge bma-badge-primary badge-pill">
                                                                    {parseFloat(value.montant).toFixed(2)}
                                                                    </span>
                                                                </h3>
                                                            </div>
                                                            {this.state.donationArray[value.id] > 0 &&
                                                                <div class="d-flex w-100 justify-content-between">
                                                                <h6 class="mb-1"><i>{this.props.t('stats.includingDonation')}</i></h6>
                                                                    <h4>
                                                                        <span class="badge bma-badge-secondary badge-pill">
                                                                        {parseFloat(this.state.donationArray[value.id]).toFixed(2)}
                                                                        </span>
                                                                    </h4>
                                                                </div>
                                                            }
                                                            </li>
                                                    })}
                                                    </ul>               
                                                </div>
                                            </div>
                                            <div class="card shadow p-3 mb-5 bg-white rounded text-center">
                                                <CSVLink target="_blank"  filename="bma_wallets.csv" data={this.state.walletsCsvData}><button type="button" class="btn btn-bma-secondary">Download</button></CSVLink>
                                            </div>
                                        </div>
                                        <div className="col-lg-8 col-md-7 col-sm-12 shadow p-3 mb-5 bg-white">
                                        <div className="row stats py-2">
                                            {/* Form START*/}
                                            <div className="col-md-12 col-sm-12">
                                                <div class="card shadow p-3 mb-5 bg-white rounded text-center">
                                                    <div class="card-body">
                                                        <form onSubmit={this.handleSubmit}>
                                                            <div class="form-row align-items-center">
                                                                <div class="col-md-5 col-sm-6 my-1">
                                                                    <div class="input-group mb-2 mr-sm-2">
                                                                        <div class="input-group-prepend">
                                                                            <div class="input-group-text">Date From</div>
                                                                        </div>
                                                                        <DatePicker
                                                                            className="form-control"
                                                                            selected={this.state.filterDateFrom}
                                                                            onChange={this.handleDateFromChange}
                                                                            showTimeSelect="true"
                                                                            // dateFormat="Pp"
                                                                            dateFormat="dd/MM/yyyy HH:mm"
                                                                            timeFormat="HH:mm"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-5 col-sm-6 my-1">
                                                                    <div class="input-group">
                                                                        <div class="input-group-prepend">
                                                                            <div class="input-group-text">Date To</div>
                                                                        </div>
                                                                        <DatePicker
                                                                            className="form-control"
                                                                            selected={this.state.filterDateTo}
                                                                            onChange={this.handleDateToChange}
                                                                            showTimeSelect
                                                                            // dateFormat="Pp"
                                                                            dateFormat="dd/MM/yyyy HH:mm"
                                                                            timeFormat="HH:mm"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form-row align-items-center">
                                                                <div class="col-md-5 col-sm-6 my-1">
                                                                    <div class="form-group my-1">
                                                                        <Select
                                                                            onChange={this.handlePosChange}
                                                                            async
                                                                            withAll={true}
                                                                            closeMenuOnSelect={true}
                                                                            options={this.state.posOptions}
                                                                            placeholder={this.props.t('pos')}
                                                                            defaultValue=''
                                                                            isClearable={true}
                                                                            value={this.state.selectedFilterPos}        
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div class="col-auto my-1">
                                                                    <button type="submit" class="btn btn-bma-primary">Submit</button>
                                                                </div>
                                                                <div class="col-auto my-1">
                                                                <button type="button"  onClick={this.handleClear} class="btn btn-bma-secondary">Clear</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Form END*/}
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <div class="card shadow p-3 mb-5 bg-white rounded text-center">
                                                    <div class="card-body">
                                                    <h5 class="card-title">{this.props.t('stats.totalTransactionsAmount')}</h5>
                                                    <h1><span class="badge bma-badge-primary">{parseFloat(this.state.stats.totalTransactionsAmount).toFixed(2)}</span></h1>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <div class="card shadow p-3 mb-5 bg-white rounded text-center">
                                                    <div class="card-body">
                                                    <h5 class="card-title">{this.props.t('stats.totalDonations')}</h5>
                                                    <h1><span class="badge bma-badge-primary">{parseFloat(this.state.stats.totalDonations).toFixed(2)}</span></h1>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <div class="card shadow p-3 mb-5 bg-white rounded text-center">
                                                    <div class="card-body">
                                                        <h5 class="card-title">{this.props.t('stats.totalIncome')}</h5>
                                                        <h1><span class="badge bma-badge-primary">{parseFloat(this.state.stats.totalIncome).toFixed(2)}</span></h1>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="transactions" title={this.props.t('stats.transactions')}>
                            <div className="row stats py-5">
                                {/* Form START*/}
                                <div className="col-md-12 col-sm-12">
                                    <div class="card shadow p-3 mb-5 bg-white rounded text-center">
                                        <div class="card-body">
                                            <form onSubmit={this.handleSubmit}>
                                                <div class="form-row align-items-center">
                                                    <div class="col-md-5 col-sm-6 my-1">
                                                        <div class="input-group mb-2 mr-sm-2">
                                                            <div class="input-group-prepend">
                                                                <div class="input-group-text">Date From</div>
                                                            </div>
                                                            <DatePicker
                                                                className="form-control"
                                                                selected={this.state.filterDateFrom}
                                                                onChange={this.handleDateFromChange}
                                                                showTimeSelect="true"
                                                                // dateFormat="Pp"
                                                                dateFormat="dd/MM/yyyy HH:mm"
                                                                timeFormat="HH:mm"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-5 col-sm-6 my-1">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <div class="input-group-text">Date To</div>
                                                            </div>
                                                            <DatePicker
                                                                className="form-control"
                                                                selected={this.state.filterDateTo}
                                                                onChange={this.handleDateToChange}
                                                                showTimeSelect
                                                                // dateFormat="Pp"
                                                                dateFormat="dd/MM/yyyy HH:mm"
                                                                timeFormat="HH:mm"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-row align-items-center">
                                                    <div class="col-md-5 col-sm-6 my-1">
                                                        <div class="form-group my-1">
                                                            <Select
                                                                onChange={this.handlePosChange}
                                                                async
                                                                withAll={true}
                                                                closeMenuOnSelect={true}
                                                                options={this.state.posOptions}
                                                                placeholder={this.props.t('pos')}
                                                                defaultValue=''
                                                                isClearable={true}
                                                                value={this.state.selectedFilterPos}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-auto my-1">
                                                        <button type="submit" class="btn btn-bma-primary">Submit</button>
                                                    </div>
                                                    <div class="col-auto my-1">
                                                    <button type="button"  onClick={this.handleClear} class="btn btn-bma-secondary">Clear</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                {/* Form END*/}
                                {/* <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div class="card shadow p-3 mb-5 bg-white rounded text-center">
                                        <div class="card-body">
                                            <h5 class="card-title">{this.props.t('stats.totalAmountSpentCashCb')}</h5>
                                            <h1><span class="badge bma-badge-primary">{parseFloat(this.state.stats.totalAmountSpentCashCb).toFixed(2)}</span></h1>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div class="card shadow p-3 mb-5 bg-white rounded text-center">
                                        <div class="card-body">
                                        <h5 class="card-title">{this.props.t('stats.countTransactions')}</h5>
                                        <h1><span class="badge bma-badge-primary">{this.state.stats.countTransactions && parseFloat(this.state.stats.countTransactions)}</span></h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div class="card shadow p-3 mb-5 bg-white rounded text-center">
                                        <div class="card-body">
                                            <h5 class="card-title">{this.props.t('stats.countQrcodesTransactions')}</h5>
                                            <h1><span class="badge bma-badge-primary">{this.state.stats.countQrcodesTransactions && parseFloat(this.state.stats.countQrcodesTransactions)}</span></h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div class="card shadow p-3 mb-5 bg-white rounded text-center">
                                        <div class="card-body">
                                            <h5 class="card-title">{this.props.t('stats.countTransactionsCashCb')}</h5>
                                            <h1><span class="badge bma-badge-primary">{this.state.stats.countTransactionsCashCb}</span></h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div class="card shadow p-3 mb-5 bg-white rounded text-center">
                                        <CSVLink target="_blank"  filename="bma-stats.csv" data={this.state.statsCsvData}><button type="button" class="btn btn-bma-secondary">Download</button></CSVLink>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div class="card shadow p-3 mb-5 bg-white rounded text-center">
                                        <div class="card-body">
                                        <h5 class="card-title">{this.props.t('stats.transactionsGraph')}</h5>
                                            {/* <Line data={data} options={options} /> */}
                                                <div id="wrapper">
                                                    <div id="chart-line2">
                                                        <Chart options={options} series={series} type="line" height={230} />
                                                    </div>
                                                    <div id="chart-line">
                                                        <Chart options={optionsLine} series={seriesLine} type="area" height={130} />
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        </Tab>
                        <Tab eventKey="products" title={this.props.t('stats.products')}>

                        <div className="row stats py-5">
                                {/* Form START*/}
                                <div className="col-md-12 col-sm-12">
                                    <div class="card shadow p-3 mb-5 bg-white rounded text-center">
                                        <div class="card-body">
                                            <form onSubmit={this.handleSubmit}>
                                                <div class="form-row align-items-center">
                                                    <div class="col-md-5 col-sm-6 my-1">
                                                        <div class="input-group mb-2 mr-sm-2">
                                                            <div class="input-group-prepend">
                                                                <div class="input-group-text">Date From</div>
                                                            </div>
                                                            <DatePicker
                                                                className="form-control"
                                                                selected={this.state.filterDateFrom}
                                                                onChange={this.handleDateFromChange}
                                                                showTimeSelect="true"
                                                                // dateFormat="Pp"
                                                                dateFormat="dd/MM/yyyy HH:mm"
                                                                timeFormat="HH:mm"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-5 col-sm-6 my-1">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <div class="input-group-text">Date To</div>
                                                            </div>
                                                            <DatePicker
                                                                className="form-control"
                                                                selected={this.state.filterDateTo}
                                                                onChange={this.handleDateToChange}
                                                                showTimeSelect
                                                                // dateFormat="Pp"
                                                                dateFormat="dd/MM/yyyy HH:mm"
                                                                timeFormat="HH:mm"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-row align-items-center">
                                                    <div class="col-md-5 col-sm-6 my-1">
                                                        <div class="form-group my-1">
                                                            <Select
                                                                onChange={this.handlePosChange}
                                                                async
                                                                withAll={true}
                                                                closeMenuOnSelect={true}
                                                                options={this.state.posOptions}
                                                                placeholder={this.props.t('pos')}
                                                                defaultValue=''
                                                                isClearable={true}
                                                                value={this.state.selectedFilterPos}                                                                
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-auto my-1">
                                                        <button type="submit" class="btn btn-bma-primary">Submit</button>
                                                    </div>
                                                    <div class="col-auto my-1">
                                                    <button type="button"  onClick={this.handleClear} class="btn btn-bma-secondary">Clear</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div class="card shadow p-3 mb-5 bg-white rounded">
                                                <div class="card-body">
                                                <h5 class="card-title">{this.props.t('stats.items')}</h5>
                                                <ul class="list-group">
                                                    <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th>{this.props.t('stats.item')}</th>
                                                            {/* <th>{this.props.t('stats.unit_price')}</th> */}
                                                            <th>{this.props.t('stats.total_sold')}</th>
                                                            <th>{this.props.t('stats.total')}</th>
                                                            <th>{this.props.t('stats.is_deposit')}</th>
                                                            </tr>
                                                    </thead>
                                                    <tbody>
                                                    {/* {this.state.itemSalesData && this.state.itemSalesData.map((trData, index) => {
                                                        return trData;
                                                    })} */}
                                                   {this.state.itemSalesData && this.state.itemSalesData.map((itemData, index) => {
                                                        return <tr key={index}>
                                                            <td>{itemData.labelItem}</td>
                                                            <td>{itemData.totalSold}</td>
                                                            <td>{itemData.total.toFixed(2)}</td>
                                                            <td>{itemData.isDeposit && <FeatherIcon icon="check" size="28" color="#36909b"/> || ''}</td>
                                                        </tr>
                                                        // return <li key={index}>{value}</li>
                                                    //     return <li class="list-group-item list-group-item-action flex-column align-items-start">
                                                    //         <div class="d-flex w-100 justify-content-between">
                                                    //             <h5 class="mb-1">{value.labelItem} </h5>
                                                    //             <h3>
                                                    //                 <span class="badge bma-badge-primary badge-pill">
                                                    //                     {value.totalSold} x {value.unitPrice} €
                                                    //                 </span>
                                                    //                 <span class="badge bma-badge-secondary badge-pill">
                                                    //                 =  {parseFloat(value.totalSold * value.unitPrice).toFixed(2)} €
                                                    //                 </span>
                                                    //             </h3>
                                                    //         </div>
                                                    //         </li>
                                                    })}
                                                    </tbody>
                                                    </table>
                                                    </ul>               
                                                </div>
                                            </div>
                                            <div class="card shadow p-3 mb-5 bg-white rounded text-center">
                                                <div class="card-body">
                                                    <h1 class="card-title">{this.props.t('stats.total')}: <span class="badge bma-badge-primary">{this.state.countTotalItemsSales}</span></h1>
                                                </div>
                                            </div>
                                            <div class="card shadow p-3 mb-5 bg-white rounded text-center">
                                                <CSVLink target="_blank"  filename="bma_item_sales.csv" data={this.state.itemSalesCsvData}><button type="button" class="btn btn-bma-secondary">Download</button></CSVLink>
                                            </div>
                                        </div>
                                {/* Form END*/}
                                {/* <DashboardFilter
                                    handleSubmit={this.handleSubmit}
                                    filterDateFrom={this.filterDateFrom}
                                    handleDateFromChange={this.handleDateFromChange}
                                    filterDateTo={this.filterDateTo}
                                    handleDateToChange={this.handleDateToChange}
                                    posOptions={this.posOptions}
                                    handlePosChange={this.handlePosChange}
                                    selectedFilterPos={this.selectedFilterPos}
                                    handleClear={this.handleClear}
                                /> */}
                            </div>
                        </Tab>
                        <Tab eventKey="wristbands" title={this.props.t('stats.wristbands')}>
                            <div className="row stats py-5">
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div class="card shadow p-3 mb-5 bg-white rounded text-center">
                                        <div class="card-body">
                                            <h5 class="card-title">{this.props.t('stats.countQrcodes')}</h5>
                                            <h1><span class="badge bma-badge-primary">{this.state.stats.countQrcodes}</span></h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div class="card shadow p-3 mb-5 bg-white rounded text-center">
                                        <div class="card-body">
                                            <h5 class="card-title">{this.props.t('stats.totalAmountOnQrcodeWallets')}</h5>
                                            <h1><span class="badge bma-badge-primary">{parseFloat(this.state.stats.totalAmountOnQrcodeWallets).toFixed(2)}</span></h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="other" title={this.props.t('stats.other')}>
                            <div className="row stats py-5">
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div class="card shadow p-3 mb-5 bg-white rounded text-center">
                                        <div class="card-body">
                                        <h5 class="card-title">{this.props.t('stats.countEmailLeads')}</h5>
                                        <h1><span class="badge bma-badge-primary">{this.state.stats.countEmailLeads && parseFloat(this.state.stats.countEmailLeads)}</span></h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
            {/* <h1>{this.props.t('dashboard')}</h1>
            <br />
                <div className="row stats">
                    <div className="col-lg-4 col-md-5 col-sm-12">
                        <div class="card shadow p-3 mb-5 bg-white rounded text-center">
                            <div class="card-body">
                                <h5 class="card-title">{this.props.t('stats.totalAmountOnQrcodeWallets')}</h5>
                                <h1><span class="badge bma-badge-primary">{parseFloat(this.state.stats.totalAmountOnQrcodeWallets).toFixed(2)}</span></h1>
                            </div>
                        </div>
                        <div class="card shadow p-3 mb-5 bg-white rounded">
                            <div class="card-body">
                            <h5 class="card-title">{this.props.t('stats.wallets')}</h5>
                            <ul class="list-group">
                                {this.state.beneficiaires.map((value, index) => {
                                    // return <li key={index}>{value}</li>
                                    return <li class="list-group-item list-group-item-action flex-column align-items-start">
                                        <div class="d-flex w-100 justify-content-between">
                                            <h5 class="mb-1">{value.nom} </h5>
                                            <h3>
                                                <span class="badge bma-badge-primary badge-pill">
                                                {parseFloat(value.montant).toFixed(2)}
                                                </span>
                                            </h3>
                                        </div>
                                        {this.state.donationArray[value.id] > 0 &&
                                            <div class="d-flex w-100 justify-content-between">
                                            <h6 class="mb-1"><i>{this.props.t('stats.includingDonation')}</i></h6>
                                                <h4>
                                                    <span class="badge bma-badge-secondary badge-pill">
                                                    {parseFloat(this.state.donationArray[value.id]).toFixed(2)}
                                                    </span>
                                                </h4>
                                            </div>
                                        }
                                        </li>
                                })}
                                </ul>               
                            </div>
                        </div>
                        <div class="card shadow p-3 mb-5 bg-white rounded text-center">
                            <CSVLink target="_blank"  filename="bma_wallets.csv" data={this.state.walletsCsvData}><button type="button" class="btn btn-bma-secondary">Download</button></CSVLink>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-7 col-sm-12 shadow p-3 mb-5 bg-white">

                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <div class="card shadow p-3 mb-5 bg-white rounded text-center">
                                    <div class="card-body">
                                        <form onSubmit={this.handleSubmit}>
                                            <div class="form-row align-items-center">
                                                <div class="col-md-5 col-sm-6 my-1">
                                                    <div class="input-group mb-2 mr-sm-2">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">Date From</div>
                                                        </div>
                                                        <DatePicker
                                                            className="form-control"
                                                            selected={this.state.filterDateFrom}
                                                            onChange={this.handleDateFromChange}
                                                            showTimeSelect="true"
                                                            // dateFormat="Pp"
                                                            dateFormat="dd/MM/yyyy HH:mm"
                                                            timeFormat="HH:mm"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-md-5 col-sm-6 my-1">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">Date To</div>
                                                        </div>
                                                        <DatePicker
                                                            className="form-control"
                                                            selected={this.state.filterDateTo}
                                                            onChange={this.handleDateToChange}
                                                            showTimeSelect
                                                            // dateFormat="Pp"
                                                            dateFormat="dd/MM/yyyy HH:mm"
                                                            timeFormat="HH:mm"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-row align-items-center">
                                                <div class="col-md-5 col-sm-6 my-1">
                                                    <div class="form-group my-1">
                                                        <Select
                                                            onChange={this.handlePosChange}
                                                            async
                                                            withAll={true}
                                                            closeMenuOnSelect={true}
                                                            options={this.state.posOptions}
                                                            placeholder={this.props.t('pos')}
                                                            defaultValue=''
                                                            isClearable={true}
                                                            value={this.state.selectedFilterPos}
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-auto my-1">
                                                    <button type="submit" class="btn btn-bma-primary">Submit</button>
                                                </div>
                                                <div class="col-auto my-1">
                                                <button type="button"  onClick={this.handleClear} class="btn btn-bma-secondary">Clear</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div class="card shadow p-3 mb-5 bg-white rounded text-center">
                                    <div class="card-body">
                                    <h5 class="card-title">{this.props.t('stats.totalTransactionsAmount')}</h5>
                                    <h1><span class="badge bma-badge-primary">{parseFloat(this.state.stats.totalTransactionsAmount).toFixed(2)}</span></h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div class="card shadow p-3 mb-5 bg-white rounded text-center">
                                    <div class="card-body">
                                    <h5 class="card-title">{this.props.t('stats.totalDonations')}</h5>
                                    <h1><span class="badge bma-badge-primary">{parseFloat(this.state.stats.totalDonations).toFixed(2)}</span></h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div class="card shadow p-3 mb-5 bg-white rounded text-center">
                                    <div class="card-body">
                                        <h5 class="card-title">{this.props.t('stats.totalIncome')}</h5>
                                        <h1><span class="badge bma-badge-primary">{parseFloat(this.state.stats.totalIncome).toFixed(2)}</span></h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div class="card shadow p-3 mb-5 bg-white rounded text-center">
                                    <div class="card-body">
                                        <h5 class="card-title">{this.props.t('stats.totalAmountSpentCashCb')}</h5>
                                        <h1><span class="badge bma-badge-primary">{parseFloat(this.state.stats.totalAmountSpentCashCb).toFixed(2)}</span></h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div class="card shadow p-3 mb-5 bg-white rounded text-center">
                                    <div class="card-body">
                                    <h5 class="card-title">{this.props.t('stats.countTransactions')}</h5>
                                    <h1><span class="badge bma-badge-primary">{parseFloat(this.state.stats.countTransactions)}</span></h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div class="card shadow p-3 mb-5 bg-white rounded text-center">
                                    <div class="card-body">
                                        <h5 class="card-title">{this.props.t('stats.countQrcodesTransactions')}</h5>
                                        <h1><span class="badge bma-badge-primary">{parseFloat(this.state.stats.countQrcodesTransactions)}</span></h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div class="card shadow p-3 mb-5 bg-white rounded text-center">
                                    <div class="card-body">
                                        <h5 class="card-title">{this.props.t('stats.countQrcodes')}</h5>
                                        <h1><span class="badge bma-badge-primary">{this.state.stats.countQrcodes}</span></h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div class="card shadow p-3 mb-5 bg-white rounded text-center">
                                    <div class="card-body">
                                    <h5 class="card-title">{this.props.t('stats.countEmailLeads')}</h5>
                                    <h1><span class="badge bma-badge-primary">{parseFloat(this.state.stats.countEmailLeads)}</span></h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div class="card shadow p-3 mb-5 bg-white rounded text-center">
                                    <CSVLink target="_blank"  filename="bma-stats.csv" data={this.state.statsCsvData}><button type="button" class="btn btn-bma-secondary">Download</button></CSVLink>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div class="card shadow p-3 mb-5 bg-white rounded text-center">
                                    <div class="card-body">
                                    <h5 class="card-title">{this.props.t('stats.transactionsGraph')}</h5>
                                        <Line data={data} options={options} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        );
    }
}

export default withTranslation()(Dashboard)