import React, { Component } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../config.js';
import { Button, Form } from 'react-bootstrap';
import SweetAlert from 'sweetalert2-react';
import { withTranslation  } from 'react-i18next';
class EditItem extends Component {

    state = {
        pos: [],
        repartitions : [],
        repartition_id: "",
        labelItem: "",
        descriptionItem: "",
        POS_id: "",
        labelPOS: "",
        prixItem: "",
        stockItem: "",
        idItem: "",
        consigne: false
    }

    constructor(props, context) {
        super(props, context);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleConsign = this.handleConsign.bind(this);
    }

    componentDidMount() {
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization,
        };
        // get repartitions
        axios.get(`${BASE_URL}/api/repartition`, { headers })
            .then(res => {
                const repartitions = res.data.repartitions;
                this.setState({
                    repartitions: repartitions,
                });
            })
            .catch(error => {
                console.log(error);
            });
        
        // get item
        axios.get(`${BASE_URL}/api/items/item/${this.props.match.params.idItem}`, { headers })
        .then(res => {
            const items = res.data.items;
            console.log(items);
            this.setState({ 
                idItem: items.idItem,
                labelItem: items.labelItem,
                descriptionItem: items.descriptionItem,
                POS_id: items.POs[0].idPOS,
                labelPOS: items.POs[0].labelPOS,
                prixItem: items.POs[0].PItem.prixItem,
                stockItem: items.POs[0].PItem.stockItem,
                consigne: items.POs[0].PItem.consigne
            });
            

        })
        .catch(error => {
            console.log(error);
        });
    }

    handleConsign = e => {
        this.setState({
            consigne: e.target.checked
        });
    }

    handleInputChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleSubmit = e => {
        e.preventDefault();

        const { labelItem, descriptionItem, POS_id, prixItem, stockItem, repartition_id, consigne } = this.state;
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization
        };
        // items
        axios.put(`${BASE_URL}/api/items/item/${this.props.match.params.idItem}`, { labelItem, descriptionItem }, { headers })
            .then(response => {

                // pitem
                axios.put(`${BASE_URL}/api/items/${this.props.match.params.idItem}/pos/${POS_id}`, { stockItem, prixItem, consigne , repartition_id }, { headers })
                    .then(response => {
                        this.setState({ show: true });
                        this.props.history.push(`/dashboard/pos/${POS_id}`);
                    })
                    .catch(error => {
                        throw (error);
                    });
            })
            .catch(error => {
                throw (error);
            });
    };

    handleCancel = () => {
        this.props.history.push(`/dashboard/pos/${this.state.POS_id}`);
    };

    render() {
        return (
            <div className="col-md-10">
                <h1>{this.props.t('edit_item')}</h1>
                <br />
                <div className="row justify-content-left">
                    <div className="col-md-6">
                        <Form className="py-3" onSubmit={this.handleSubmit}>
                            <Form.Group controlId="formLabel">
                                <Form.Label>{ this.props.t('forms.items.label') }</Form.Label>
                                <Form.Control type="text" placeholder="Label" name="labelItem" value={this.state.labelItem} onChange={this.handleInputChange} required />

                            </Form.Group>
                            <Form.Group controlId="formDescription">
                                <Form.Label>{ this.props.t('forms.items.description') }</Form.Label>
                                <Form.Control as="textarea" rows="3" name="descriptionItem" value={this.state.descriptionItem} onChange={this.handleInputChange} />
                            </Form.Group>
                            <Form.Group controlId="formPrix">
                                <Form.Label>{this.props.t('prix')}</Form.Label>
                                <Form.Control type="text" placeholder={this.props.t('prix')} name="prixItem" value={this.state.prixItem} onChange={this.handleInputChange} required />
                            </Form.Group>
                            <Form.Group controlId="formstock">
                                <Form.Label>{this.props.t('stock')}</Form.Label>
                                <Form.Control type="text" placeholder={this.props.t('stock')} name="stockItem" value={this.state.stockItem} onChange={this.handleInputChange} required />
                            </Form.Group>
                            <Form.Group controlId="formRepartition">
                                <Form.Label>{this.props.t('repartition')}</Form.Label>
                                <Form.Control as="select" name="repartition_id" value={this.state.repartition_id} onChange={this.handleInputChange}>
                                    <option value="">{ this.props.t('choose_repartition') }</option>
                                    {this.state.repartitions.map((repartition, index) =>
                                        <option key={ index } value={repartition.id}>{repartition.nom}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="formConsign">
                                <Form.Check checked={this.state.consigne} type="checkbox" onChange={this.handleConsign} name="consign" label={this.props.t('consign')} />
                            </Form.Group>
                            <div className="mt-4 text-right">
                                <Button className="mr-2" variant="outline-dark" type="submit">{this.props.t('btn_save')}</Button>
                                <Button className=" btn btn-link mr-2" variant="outline-link" onClick={this.handleCancel}>
                                    {this.props.t('retour_liste')}
                                </Button>
                            </div>

                        </Form>
                    </div>
                </div>
                <SweetAlert
                    show={this.state.show}
                    text="Insertion avec succès"
                    confirmButtonText='OK'
                    type="success"
                    onConfirm={() => this.setState({ show: false })}
                />

            </div>
        );
    }
}

export default withTranslation()(EditItem);