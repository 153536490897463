import React, { Component } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../config.js';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { withTranslation } from 'react-i18next';
import 'moment-timezone';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';


class Category extends Component {
    state = {
        events: []
    }

    // constructor(props, context) {
    //     super(props, context);

    // }

    componentDidMount() {
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization,
        };
        axios.get(`${BASE_URL}/api/events?type=of`, { headers })
            .then(res => {
                const events = res.data.events;
                this.setState({ events: events });
            })
            .catch(error => {
                console.log(error);
            });
    }

    render() {
        const actionsFormatter = (cell, row, rowIndex) => {
          return <div className="text-center">
                <Link to={"/dashboard/categories/" + cell}>
                    <FeatherIcon icon="edit" size="28" color="#36909b" />
                </Link>
            </div>
        };
        const colorCellFormatter = (field, row, rowIndex) => {
            return <span style={{ backgroundColor: field }}>
                    {field}
                </span>
        }

        const cols = [{
                dataField: 'nom',
                text: this.props.t('name'),
                sort: true
            }, {
                dataField: 'color',
                text: this.props.t('color'),
                formatter: colorCellFormatter
            }, 
            {
                dataField: 'id',
                text: 'Action',
                formatter: actionsFormatter
            },
        ];
        return (
            <div className="col-md-10">
                <h1>{this.props.t('categories')}</h1>
                <br />
                           
                <BootstrapTable 
                    keyField='id' 
                    data={ this.state.events } 
                    columns={ cols } 
                    pagination={ paginationFactory() }
                />
            </div>
        );
    }
    
}

export default withTranslation()(Category);