import React, { Component } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../config.js';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import SweetAlert from 'sweetalert2-react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
const { ExportCSVButton } = CSVExport;

class Subscribers extends Component {
    
    state = {
        leads: []
      }
      
    constructor (props,context){
        super(props,context);
        // super();
    }
    
      componentDidMount() {
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization,
          };
        axios.get(`${BASE_URL}/api/lead`, {headers})
          .then(res => {
            const leads = res.data.leads;
            console.log('res.data >> ', res.data);
            this.setState({ leads : leads });
          })
          .catch(error => {
              console.log(error);
          });
      }

    render() {

        const cols = [{
                dataField: 'email',
                text: 'Email',
                sort: true
            }
        ];
        return (
            <div className="col-md-10">
                <h1>Liste Subscribers </h1>
                <br />
                
                <ToolkitProvider
                  keyField="id"
                  data={ this.state.leads }
                  columns={ cols }
                  exportCSV
                >
                  {
                    props => (
                      <div>
                        <ExportCSVButton { ...props.csvProps } class="btn btn-primary">{this.props.t('event_subscribers_export')}</ExportCSVButton>
                        <hr />
                        <BootstrapTable { ...props.baseProps } pagination={ paginationFactory() }/>
                      </div>
                    )
                  }
                </ToolkitProvider>
                </div>
        );
    }
}

export default withTranslation()(Subscribers);