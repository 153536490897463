import React, { Component } from 'react';
import axios from 'axios';
import '../assets/css/login.css';
import { BASE_URL } from '../config';
import Moment from 'react-moment';
import soku from '../assets/img/soku.svg';
import bmaLogo from '../assets/img/bmaLogo.png';
import gplay from '../assets/img/gplay.png';
import appstore from '../assets/img/appstore.png';
import bmaFoot from '../assets/img/bma.png';
import sieste_logo from '../assets/img/sieste_logo.jpg';
import euFoot from '../assets/img/eu.jpg';
import Logo_Occitanie from '../assets/img/Logo_Occitanie.svg';
import logo_mairie_toulouse from '../assets/img/logo-mairie-toulouse.png';
class Webui extends Component {
    state = {
        benefs: [],
        transactions: [],
        beneficiaires: [],
    };
    constructor(props, context) {
        super(props, context);
        this.renderBenf = this.renderBenf.bind(this);

    }
    componentDidMount() {
        this.renderData();
        setInterval(() => {
            this.renderData();
        }, 5000);
    }

    renderData() {
        const key = 'x-id';
        localStorage.removeItem(key);

        const headers = {
            'Content-Type': 'application/json',
            'x-id': 5
        };
        axios.get(`${BASE_URL}/api/mobile/data`, { headers })
            .then(res => {
                const benefs = res.data.data
                this.setState({
                    benefs: benefs,
                })
            })
            .catch(error => {
                throw (error);
            })

        axios.get(`${BASE_URL}/api/mobile/transactions`)
            .then(res => {
                const transactions = res.data.history
                // const beneficiaires = transactions.Beneficiaire
                this.setState({
                    transactions: transactions,
                })
                // console.log('BENEF ', transactions.Beneficiaire);
            })
            .catch(error => {
                throw (error);
            })
    }
    renderBenf = (id) => (e) => {
        window.localStorage.setItem('x-id', id);
    }

    render() {
        return (
            <section>
                <nav className="navbar navbar-light" style={{ backgroundColor: '#6f96cb' }}>
                    <a className="py-0 navbar-brand" href="https://www.blockchainmyart.org/">
                        <img src={bmaLogo} width="170" height="65" alt="BMA logo"/>
                    </a>

                    <img className="float-right card-img-top" style={{ width: '10%' , height :'4vw' }}  src={ sieste_logo } />

                    
                </nav>
                <div className="mt-3 mx-4">
                    <div className="justify-content-center">
                        <div className="col-12">
                            <div className="d-flex align-items-center justify-content-center mb-3">
                                <p className="h4">
                                    Toutes les info sur votre festival dans votre poche ? Installez l'appli Blockchain My Art sur votre smartphone !
                                </p>
                                <div>
                                    <img src={gplay} className="ml-3" width="150" height="55" alt="google play logo"/>
                                    <img src={appstore} className="ml-3" width="150" height="50" alt="apple store logo"/>
                                </div>
                            </div>
                            <h1 className="font-weight-bold text-dark text-center my-0">
                                Accounts
                            </h1>
                            <div className="my-0 table-responsive">
                                <table className="table table-bordered table-hover">
                                    <thead style={{ backgroundColor: '#f3715a' }}>
                                        <tr>
                                            {this.state.benefs.map((benef, i) =>
                                                <th className="py-2" key={i} scope="col">
                                                    <span>
                                                        {benef.name}
                                                    </span>
                                                </th>
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr >
                                            {this.state.benefs.map((benef, i) =>
                                                <th key={i} scope="col" className="py-0">
                                                    <span>
                                                        {benef.value}
                                                    </span>
                                                </th>
                                            )}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <h1 className="font-weight-bold text-dark text-center my-0">
                                Recent Transactions
                            </h1>
                            <div className="my-0 table-responsive">
                                <table className="table table-bordered table-hover">
                                    <thead style={{ backgroundColor: '#f3715a' }}>
                                        <tr>
                                            <th className="py-2">Timestamp</th>
                                            <th className="py-2">Value</th>
                                            <th className="py-2">From</th>
                                            <th className="py-2">To</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.transactions.map((transaction, i) =>
                                            <tr >
                                                <th className="py-0">
                                                    <Moment format="D MMM YYYY h:mm:ss" withTitle>
                                                        {transaction.createdAt}
                                                    </Moment>
                                                </th>
                                                <th className="py-0">{transaction.amount}</th>
                                                <th className="py-0">{this.state.benefs.map((b, i) =>
                                                    (b.is_default == true) ?
                                                        <span key={ i }>{ b.walletAddress }</span>
                                                        :
                                                        ''

                                                )}</th>
                                                <th className="py-0">{(transaction.Beneficiaire) ? transaction.Beneficiaire.walletAddress : ''}</th>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-10 mx-auto">
                            <div className="row">
                                <div className="col-2 text-center">
                                    <img className="card-img-top" style={{ width: '50%' , height :'8vw' }}  src={ bmaFoot } />
                                </div>
                                <div className="col-3 text-center">
                                    <img className="card-img-top" style={{ width: '100%' , height :'10vw' }} src={ euFoot } />
                                </div>
                                <div className="col-2 text-center">
                                    <img className="card-img-top align-middle" style={{ width: '100%' , height :'10vw' }} src={ soku } />
                                </div>
                                <div className="col-2 text-center">
                                    <img className="card-img-top" style={{ width: '50%' , height :'8vw' }}  src={ logo_mairie_toulouse } />                                    
                                </div>
                                <div className="col-2 text-center">
                                    <img className="card-img-top" style={{ width: '50%' , height :'8vw' }} src={ Logo_Occitanie } />
                                </div>
                            </div>

                        </div>




                    </div>
                </div>
            </section>

        );
    }
}

export default Webui;
