import React, { Component } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../config.js';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { withTranslation } from 'react-i18next';
import SweetAlert from 'sweetalert2-react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';

class Beneficiaire extends Component {

    state = {
        beneficiaires: []
    }

    constructor(props, context) {
        super(props, context);
        // this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization,
        };
        // get beneficiaires
        axios.get(`${BASE_URL}/api/beneficiaires`, {headers})
            .then(res => {
                const beneficiaires = res.data.beneficiares;

                this.setState({ beneficiaires: beneficiaires });
            })
            .catch(error => {
                console.log(error);
            });
    }

    handleDelete = (id) => {
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Authorization': Authorization,
            'Content-Type': 'application/json'
        };

        axios.delete(`${BASE_URL}/api/beneficiaires/${id}`, { headers })
            .then(response => {
                this.setState({ show: true });
                axios.get(`${BASE_URL}/api/beneficiaires`, {headers})
                .then(res => {
                    const beneficiaires = res.data.beneficiares;

                    this.setState({ beneficiaires: beneficiaires });
                })
                .catch(error => {
                    console.log(error);
                });
            })
            .catch(error => {
                throw (error);
            });
    }


    render() {
        const actionsFormatter = (cell, row, rowIndex) => {
            console.log('ROW ', row);
            if (row.is_default == true || row.is_deposit == true) {
                return <div className="d-flex justify-content-around">
                    </div>
            } else {
                return <div className="d-flex justify-content-around">
                    <Link to={"/dashboard/beneficiaires/" + cell}>
                        <FeatherIcon icon="eye" size="48" color="#36909b" />
                    </Link>
                    <Link to="/dashboard/beneficiaires"
                        onClick={() => {
                            if (window.confirm(`${this.props.t('confirm_del_beneficiaire')}`)) {
                                this.handleDelete(cell);
                            }
                        }}
                    >
                        <FeatherIcon icon="trash-2" size="28" color="#f36b21" />
                    </Link>
        
                </div>
            }
        };
        const colorCellFormatter = (field, row, rowIndex) => {
            return <span style={{ backgroundColor: field }}>
                    {field}
                </span>
        }

        const cols = [{
                dataField: 'nom',
                text: this.props.t('name'),
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { width: '10%', textAlign: 'center' };
                },
            }, {
                dataField: 'walletAddress',
                text: 'Wallet',
                headerStyle: (colum, colIndex) => {
                    return { width: '30%', textAlign: 'center' };
                },
            }, {
                dataField: 'txHash',
                text: 'TxHash',
                headerStyle: (colum, colIndex) => {
                    return { width: '30%', textAlign: 'center' };
                },
            }, 
            {
                dataField: 'id',
                text: 'Action',
                headerStyle: (colum, colIndex) => {
                    return { width: '10%', textAlign: 'center' };
                },
                formatter: actionsFormatter
            },
        ];
        return (
            <div className="col-md-12">
                <h1>{this.props.t('beneficiaires')}</h1>
                <br />
                <BootstrapTable 
                    keyField='id' 
                    data={ this.state.beneficiaires } 
                    columns={ cols } 
                    pagination={ paginationFactory() }
                />
                <SweetAlert
                    show={this.state.show}
                    text="Success"
                    onConfirm={() => this.setState({ show: false })}
                />
            </div>
        );
    }
}

export default withTranslation()(Beneficiaire);