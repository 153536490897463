import React, { Component } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../config.js';
import { Button, Form } from 'react-bootstrap';
import SweetAlert from 'sweetalert2-react';
import { withTranslation  } from 'react-i18next';
class AddPos extends Component {
    
    state = {
        labelPOS: "",
        descriptionPOS: "",
        deviceIDPOS: "",
        repartitions: [],
        repartition_id: "",
        errors: {},
        is_bank: false,
        hasDefaultBeneficiary: true
    }

    constructor (props,context){
        super(props,context);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentDidMount () {
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization
        };
        // get repartitions
        axios.get(`${BASE_URL}/api/repartition`, { headers })
            .then(res => {
                const repartitions = res.data.repartitions;
                this.setState({
                    repartitions: repartitions,
                });
            })
            .catch(error => {
                console.log(error);
            });
        // get default beneficiaire of festival
        axios.get(`${BASE_URL}/api/beneficiaires/default`, { headers })
            .then(res => {
                if (res.data.beneficiares && res.data.beneficiares.length == 0) {
                    this.setState({
                        hasDefaultBeneficiary: false
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
    }
    
    handleInputChange = event => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
        // this.setState({
        //     [e.target.name]: e.target.value
        // });
    };

    handleSubmit = e => {
        console.log('handle submit')
        e.preventDefault();
        this.setState({
            errors: {}
        });
        if (this.state.labelPOS.trim()) {
                const { labelPOS, descriptionPOS, deviceIDPOS, repartition_id, hasDefaultBeneficiary, is_bank } = this.state;
                if ((!repartition_id || repartition_id == '') && hasDefaultBeneficiary == false) {
                    console.log('Repartition can not be empty. Default Beneficiary is missing for the festival');
                    this.setState({errors: { repartition_id: {field: "repartition_id", validatorKey: "isNotNull"}}});
                    throw 'Data Missing';
                }
                const Authorization = localStorage.getItem('token');
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': Authorization
                };

                axios.post(`${BASE_URL}/api/pos`, { 
                    labelPOS: labelPOS.trim(), 
                    descriptionPOS: descriptionPOS.trim(), 
                    deviceIDPOS: deviceIDPOS.trim(), 
                    repartition_id,
                    is_bank: is_bank
                }, {headers})
                .then(response => {
                    this.setState({ show: true });
                    this.props.history.push("/dashboard/pos");
                })
                .catch(error => {
                    throw(error);
                });
            }
    };

    handleReset = () => {
        this.setState({
            labelPOS: "",
            descriptionPOS: "",
            deviceIDPOS: ""
        });
    };

    render() {
        return (
            <div className="col-md-10">
                <h1>{this.props.t('add_pos')}</h1>
                <br />
                <div className="row justify-content-left">
                    <div className="col-md-6">
                    <Form className="py-3" onSubmit={ this.handleSubmit }>
                        <Form.Group controlId="formLabel">
                            <Form.Label>{this.props.t('forms.pos.label')}</Form.Label>
                            <Form.Control type="text" placeholder="Label" name="labelPOS" onChange={ this.handleInputChange } required/>
                            
                        </Form.Group>
                        <Form.Group controlId="formDescription">
                            <Form.Label>{this.props.t('forms.pos.description')}</Form.Label>
                            <Form.Control as="textarea" rows="3" name="descriptionPOS" onChange={ this.handleInputChange } />
                            
                        </Form.Group>
                        <Form.Group controlId="formDevice">
                            <Form.Label>{this.props.t('forms.pos.device')}</Form.Label>
                            <Form.Control type="text" placeholder="Device" name="deviceIDPOS" onChange={ this.handleInputChange }/>
                            <p className="text-small text-left text-muted"><i>{ this.props.t('forms.pos.device_desc') }</i></p>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{this.props.t('repartition')}</Form.Label>
                            <Form.Control as="select" name="repartition_id" onChange={this.handleInputChange}>
                                <option value="">{ this.props.t('choose_repartition') }</option>
                                {this.state.repartitions.map((repartition, index) =>
                                    <option key={ index } value={repartition.id}>{repartition.nom}</option>
                                )}
                            </Form.Control>
                            { (this.state.errors && 'repartition_id' in this.state.errors) ? <p className="text-right text-danger">{this.props.t(`error.msg.notNull`)}</p> : <p></p> }
                        </Form.Group>
                        <Form.Group controlId="formIsBank">
                            <Form.Check type="checkbox" checked={this.state.is_bank} onChange={this.handleInputChange} name="is_bank" label={this.props.t('forms.pos.is_bank')} />
                            <p className="text-small text-left text-muted"><i>{ this.props.t('forms.pos.is_bank_desc') }</i></p>
                        </Form.Group>
                        <div className="mt-5 text-right">
                            
                            <Button className="mr-2" variant="outline-secondary" onClick={this.handleReset} type="reset">{this.props.t('btn_cancel')}</Button>
                            <Button className="mr-2" variant="outline-dark" type="submit">{this.props.t('btn_save')}</Button>
                        </div>
                    </Form>
                    </div>
                    <SweetAlert
                        show={this.state.show}
                        text="Insertion Success"
                        confirmButtonText='OK'
                        type="success"
                        onConfirm={() => this.setState({ show: false })}
                    />
                </div>

                
            </div>
        );
    }
}

export default withTranslation()(AddPos);