import React, { Component } from 'react';
import FeatherIcon from 'feather-icons-react';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import { BASE_URL, APP_LOGO } from '../../config';
class Header extends Component {

  btn_logout;
  nav_fixed_top;
  state = {
    bg: ""
  }
  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
    this.lang = this.lang.bind(this);
  }

  lang = (langue) => (e) => {
    this.props.i18n.changeLanguage(langue);

  }

  logout() {
    this.props.callLogout()
      .then(() => {

      });

  }
  componentDidMount() {
    if (this.props.isAuth === true) {
      setTimeout(() => {
        const Authorization = localStorage.getItem('token');
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': Authorization
        };

        axios.get(`${BASE_URL}/api/config`, { headers })
          .then(res => {
            const configs = res.data.config;
            configs.forEach((item) => {
              if (item.key === "bg") {
                this.setState({ bg: item.value });
              }
              console.log('BG ::::::: ', this.state.bg);
            })
          })
          .catch(error => {
            console.log(error);
          });
      }, 500);
    }
  }
  componentWillReceiveProps(nextProps) {
    // console.log('CONSOLE ::::::: ');

    if (nextProps.isAuth === true) {
      setTimeout(() => {
        const Authorization = localStorage.getItem('token');
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': Authorization
        };

        axios.get(`${BASE_URL}/api/config`, { headers })
          .then(res => {
            const configs = res.data.config;
            configs.forEach((item) => {
              if (item.key === "bg") {
                this.setState({ bg: item.value });
              }
              console.log('BG ::::::: ', this.state.bg);
            })
          })
          .catch(error => {
            console.log(error);
          });
      }, 500);
    }
  }
  render() {

    let src_logo;
    let btn_logout;
    let nav_fixed_top;
    if (this.props.isAuth === true) {
      src_logo = this.state.bg;
      console.log(' SRC_LOGO : ', this.state.bg);

      btn_logout = (
        <span>
          <button className="btn btn-link" onClick={this.logout}> <FeatherIcon icon="log-out" /> {this.props.t('logout')}</button>
        </span>
      );
      nav_fixed_top = "navbar navbar-white bg-white  fixed-top flex-md-nowrap p-0";
    } else {
      src_logo = "bg.svg";
      nav_fixed_top = "navbar navbar-white bg-white flex-md-nowrap p-0";
    }

    return (
      <nav className={nav_fixed_top}>
        <a className="navbar-brand col-sm-3 col-md-2 mr-0 py-1" href="/">
          {/* <img src={(src_logo) ? BASE_URL + "/" + src_logo : BASE_URL + "/bg.svg"} */}
          <img src={APP_LOGO || BASE_URL + "/bg.svg"}
            // width="160"
            height="65"
            alt="Blockhain My Art logo"
          />
        </a>
        <ul className="nav justify-content-end">
          <li className="nav-item">
            <span className="nav-link" onClick={this.lang('en')}>EN</span>
          </li>
          <li className="nav-item">
            <span className="nav-link" onClick={this.lang('fr')}>FR</span>
          </li>
          <li className="nav-item">
            <span className="nav-link" onClick={this.lang('it')}>IT</span>
          </li>
          <li className="nav-item">
            <span className="nav-link" onClick={this.lang('de')}>DE</span>
          </li>
          <li className="nav-item ml-4">
            {btn_logout}
          </li>
        </ul>
      </nav>
    );
  }
}


export default withTranslation()(Header);
