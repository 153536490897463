import React, { Component } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../assets/css/login.css';
import { BASE_URL } from '../config';
import SweetAlert from 'sweetalert2-react';
class Benef extends Component {
    state = {
        benefs: []
    };
    constructor(props, context) {
        super(props, context);
        this.renderHistory = this.renderHistory.bind(this);

    }
    componentDidMount() {
        const key = 'x-idBenf';
        localStorage.removeItem(key);

        const x_id = localStorage.getItem('x-id');
        const headers = {
            'Content-Type': 'application/json',
            'x-id': x_id
        };
        axios.get(`${BASE_URL}/api/mobile/data`, { headers })
            .then(res =>{
                const benefs = res.data.data
                this.setState({
                    benefs: benefs,
                })
                console.log('benef ', res);
            })
            .catch(error => {
                throw (error);  
            })
    }

    renderHistory = (id) => (e) => {
        window.localStorage.setItem('x-idBenf', id);
    }

    render() {
        return (
            <section className="main">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-5 col-10">
                            <h1 className="text-center my-3">
                                <a href="#/webui" className="text-primary">WEB UI (dataviz)</a>
                            </h1>
                            <h3 className="text-center mt-5">Choose Beneficiary</h3>
                            <div className="my-5">
                                { this.state.benefs.map((benef,i) => 
                                    <ul key={ i } className="list-group list-group-flush">
                                        <Link to={"/webui/beneficiaires/hirtory/"} onClick={ this.renderHistory(benef.idBenef) } className="text-dark list-group-item mb-2" style={{ backgroundColor: 'rgba(226, 99, 38, 0.85)'}}>
                                            { benef.name }
                                        </Link>
                                    </ul>
                                ) }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Benef;
