import React, { Component } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../config.js';
import { withTranslation } from 'react-i18next';
import { Button, Form, Row, Col } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import Select, {components} from 'react-select';
import moment from 'moment';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import { fr, enGB }  from 'date-fns/locale'
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/es/index";
import SweetAlert from 'sweetalert2-react';
registerLocale('fr', fr);
registerLocale('en', enGB);

class EventConfig extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            configs : [],
            debut: new Date(),
            fin: new Date(),
            date_from : "",
            date_from_id : "",
            date_to : "",
            date_to_id : "",
            dateToError: null,
            cashlessError: null,
            cashless: "",
            cashless_id: "",
            selectedCashlessValue: null
        };
        this.handleSubmitDateFrom = this.handleSubmitDateFrom.bind(this);
        this.handleSubmitDateTo = this.handleSubmitDateTo.bind(this);
        this.handleDateFromChange = this.handleDateFromChange.bind(this);
        this.handleDateToChange = this.handleDateToChange.bind(this);
        this.setInitialDate = this.setInitialDate.bind(this);
        this.handleSubmitCashless = this.handleSubmitCashless.bind(this);
        this.handleCashlessChange = this.handleCashlessChange.bind(this);

    }
    handleDateFromChange(date) {
        if (date || date !== "") {
            this.setState({
                date_from: date
            });
        }
    }
    handleDateToChange(date) {
        if (date || date !== "") {
            this.setState({
                date_to: date
            });
        }
    }
    handleCashlessChange = (selectValue) => {
        console.log('handleCashlessChange >> ', selectValue.value);
        this.setState({cashless: selectValue.value, selectedCashlessValue: selectValue});

    }
    setInitialDate() {
        const { date_from, date_to } = this.state;
        console.log('set init date', date_from);
        if (date_to == "" && (date_from || date_from !== "")) {
            this.setState({
                date_to: date_from
            });
        }
    }
    handleEndChange(date) {
        this.setState({
          fin: date
        });
    }

    componentDidMount() {
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization,
        };
        axios.get(`${BASE_URL}/api/config`, { headers })
        .then(res => {
            if (res.data.status === "success") {
                const configs = res.data.config;
                configs.forEach((item) => {
                    if (item.key === "date_from")
                    {
                        this.setState({ 
                            date_from : new Date(item.value),
                            date_from_id : item.id,
                         });
                    } else if(item.key === "date_to")
                    {
                        this.setState({ 
                            date_to : new Date(item.value),
                            date_to_id : item.id
                         });
                    } else if(item.key === "cashless")
                    {
                        let selectedCashlessValue;
                        // get value and set selected value
                        if (item.value == 0 || item.value == '0' || item.value === false) {
                            selectedCashlessValue = { value: "0", label: this.props.t('no') };
                        } else if (item.value == 1 || item.value == '1' || item.value === true) {
                            selectedCashlessValue = { value: "1", label: this.props.t('yes') };
                        }
                        this.setState({ 
                            cashless : item.value,
                            cashless_id : item.id,
                            selectedCashlessValue : selectedCashlessValue
                         });
                    }
                });
                console.log(this.state);
                // if (!this.state.date_from) {
                //     this.state.date_from = new Date();
                // }
            }
        })
        .catch(error => {
            console.log(error);
        });

    }

    handleInputChange = e => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        });
        // console.log("NAME", e.target.value);
    };
    
    handleSubmitDateFrom = (id) => (e) => {
        e.preventDefault();
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization,
        };
        const { date_from } = this.state;
        axios.post(`${BASE_URL}/api/config/`, { key: 'date_from', value : date_from }, { headers })
            .then(res => {
                if(res.data.status === "success") {
                    this.setState({ show: true });
                }
            })
            .catch(error =>{
                throw(error);
            })
    }
    handleSubmitDateTo = (id) => (e) => {
        e.preventDefault();
        const { date_from, date_to } = this.state;

        const dateFrom = moment(date_from);
        const dateTo = moment(date_to);
        if (date_to && dateTo && date_from && dateFrom && (dateTo.unix() < dateFrom.unix() )) {

            this.setState({
                dateToError: 'error.fin.incompatible'
            });
            throw ('Incompatible Dates');
        }
        this.setState({
            dateToError: null
        });
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization,
        };
        axios.post(`${BASE_URL}/api/config/`, { key: 'date_to', value : date_to }, { headers })
            .then(res => {
                if(res.data.status === "success") {
                    this.setState({ show: true });
                }
            })
            .catch(error =>{
                throw(error);
            })
    }
    handleSubmitCashless = (id) => (e) => {
        e.preventDefault();
        const { cashless } = this.state;
        if (cashless == "" || cashless == null || cashless == undefined) {

            this.setState({
                cashlessError: 'error.cashless.notSelected'
            });
            throw ('cashless Not Selected');
        }
        this.setState({
            cashlessError: null
        });
        const Authorization = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Authorization,
        };
        axios.post(`${BASE_URL}/api/config/`, { key: 'cashless', value : cashless.toString() }, { headers })
            .then(res => {
                if(res.data.status === "success") {
                    this.setState({ show: true });
                }
            })
            .catch(error =>{
                throw(error);
            })
    }

    render() {

        return (
            <section>
                <div className="col-md-12">
                    <h1>{this.props.t('event_settings_title')}</h1>
                    <hr/>
                </div>
                <div className="col-md-12">
                    <h3>{this.props.t('event_dates_title')}</h3>
                        <Row>
                            <Form className="py-2 col-6">
                                <Col  xs={12}>
                                    <Form.Label>{this.props.t('dateFrom')}</Form.Label>
                                    <br/>
                                    <DatePicker
                                        className="form-control"
                                        selected={this.state.date_from}
                                        onChange={this.handleDateFromChange}
                                        showTimeSelect
                                        // dateFormat="Pp"
                                        dateFormat="dd/MM/yyyy HH:mm"
                                        timeFormat="HH:mm"
                                        locale="en"
                                        popperPlacement="auto-right"
                                        popperModifiers={{
                                            flip: {
                                                behavior: ["auto-right"] // don't allow it to flip to be above
                                            },
                                            preventOverflow: {
                                                enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                            },
                                            hide: {
                                                enabled: false // turn off since needs preventOverflow to be enabled
                                            }
                                        }}
                                    />
                                </Col>  
                                <Col  xs={12}>
                                    <Button className="mt-2 float-left" variant="outline-dark" onClick={this.handleSubmitDateFrom(this.state.date_from_id)}>{this.props.t('btn_save')}</Button>
                                </Col>  
                            </Form>
                            <Form className="py-2 col-6">
                                <Col  xs={12}>
                                    <Form.Label>{this.props.t('dateTo')}</Form.Label>
                                    <br/>
                                    <DatePicker
                                        className="form-control"
                                        selected={this.state.date_to}
                                        onChange={this.handleDateToChange}
                                        showTimeSelect
                                        // dateFormat="Pp"
                                        dateFormat="dd/MM/yyyy HH:mm"
                                        timeFormat="HH:mm"
                                        locale="en"
                                        popperPlacement="auto-right"
                                        popperModifiers={{
                                            flip: {
                                                behavior: ["auto-right"] // don't allow it to flip to be above
                                            },
                                            preventOverflow: {
                                                enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                            },
                                            hide: {
                                                enabled: false // turn off since needs preventOverflow to be enabled
                                            }
                                        }}
                                    />
                                    { (this.state.dateToError) ? <p className="text-left text-danger">{this.props.t(this.state.dateToError)}</p> : <p></p> }
                                </Col> 
                                <Col  xs={12}>
                                    <Button className="mt-2 float-left" variant="outline-dark" onClick={this.handleSubmitDateTo(this.state.date_to_id)}>{this.props.t('btn_save')}</Button>
                                </Col> 
                            </Form>
                        </Row>
                </div>
                <div className="col-md-12">
                    <h3>{this.props.t('event_cashless_title')}</h3>
                        <Row>
                            <Form className="py-2 col-6">
                                <Col  xs={3}>
                                    <Form.Label>{this.props.t('forms.config.cashless')}</Form.Label>
                                    <br/>
                                    
                                    <Select
                                            closeMenuOnSelect={true}
                                            options={[
                                                { value: "1", label: this.props.t('yes') },
                                                { value: "0", label: this.props.t('no') }
                                              ]}
                                            placeholder="Cashless"                                          
                                            value={this.state.selectedCashlessValue}
                                            onChange={this.handleCashlessChange}
                                        />
                                        { (this.state.cashlessError) ? <p className="text-left text-danger">{this.props.t(this.state.cashlessError)}</p> : <p></p> }
                                </Col>  
                                <Col  xs={5}>
                                    <Button className="mt-2 float-left" variant="outline-dark" onClick={this.handleSubmitCashless(this.state.cashless)}>{this.props.t('btn_save')}</Button>
                                </Col>  
                            </Form>
                        </Row>
                </div>

                <SweetAlert
                    show={this.state.show}
                    text="Success"
                    confirmButtonText='OK'
                    type="success"
                    onConfirm={() => this.setState({ show: false })}
                />
            </section>
        );
    }
}

export default withTranslation()(EventConfig);